/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
};

export enum AcceptableDataSubscriptionOption {
  CoolingOff = 'COOLING_OFF',
  InArrears = 'IN_ARREARS',
  ReadyForUpgrade = 'READY_FOR_UPGRADE',
}

export type Address = Timestamps & {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  line3?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type AdjustmentCostSummary = {
  __typename?: 'AdjustmentCostSummary';
  category: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  origin: Scalars['String']['output'];
  recurring?: Maybe<TradeInRecurringCostSummary>;
  recurringTotalAmount: TaxableAmount;
  total?: Maybe<Money>;
};

export type Asset = Timestamps & {
  __typename?: 'Asset';
  blockState: AssetBlockStateType;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  imeiNumber?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  variant: Variant;
};

export enum AssetBlockStateType {
  /** Blocked */
  Blocked = 'BLOCKED',
  /** Block requested */
  BlockRequested = 'BLOCK_REQUESTED',
  /** Block request submitted */
  BlockRequestSubmitted = 'BLOCK_REQUEST_SUBMITTED',
  /** Unblocked */
  Unblocked = 'UNBLOCKED',
  /** Unblock requested */
  UnblockRequested = 'UNBLOCK_REQUESTED',
  /** Unblock request submitted */
  UnblockRequestSubmitted = 'UNBLOCK_REQUEST_SUBMITTED',
}

export type AssetReturn = {
  __typename?: 'AssetReturn';
  customerCollectionUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  state: AssetReturnState;
};

export enum AssetReturnState {
  AssessmentBlocked = 'ASSESSMENT_BLOCKED',
  AssessmentCompleted = 'ASSESSMENT_COMPLETED',
  Cancelled = 'CANCELLED',
  CollectionCompleted = 'COLLECTION_COMPLETED',
  CollectionStarted = 'COLLECTION_STARTED',
  Pending = 'PENDING',
  Requested = 'REQUESTED',
}

/** Autogenerated return type of AuthenticateClient. */
export type AuthenticateClientPayload = {
  __typename?: 'AuthenticateClientPayload';
  accessToken: ClientAuthenticationToken;
  /** @deprecated Use access_token instead */
  clientAuthToken: Scalars['String']['output'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountNumber: Scalars['String']['output'];
  accountType?: Maybe<BankAccountType>;
  branchIdentifier: Scalars['String']['output'];
};

export enum BankAccountType {
  /** Business */
  Business = 'BUSINESS',
  /** Personal */
  Personal = 'PERSONAL',
}

export type BankingConnection = {
  __typename?: 'BankingConnection';
  authUri?: Maybe<Scalars['String']['output']>;
  hasProviderErrors: Scalars['Boolean']['output'];
  /** @deprecated Use authUri instead */
  oauthRedirectUri?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  state: BankingConnectionState;
};

export type BankingConnectionAuthUriArgs = {
  callbackUri: Scalars['String']['input'];
};

export type BankingConnectionOauthRedirectUriArgs = {
  callbackUri: Scalars['String']['input'];
};

export enum BankingConnectionState {
  /** Authorized */
  Authorized = 'AUTHORIZED',
  /** Bypassed */
  Bypassed = 'BYPASSED',
  /** Established */
  Established = 'ESTABLISHED',
  /** Refuted */
  Refuted = 'REFUTED',
  /** Unauthorized */
  Unauthorized = 'UNAUTHORIZED',
  /** Verified */
  Verified = 'VERIFIED',
}

/** Autogenerated return type of CancelOrder. */
export type CancelOrderPayload = {
  __typename?: 'CancelOrderPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean']['output'];
};

export enum CancelOrderReasonType {
  /** Better price available elsewhere */
  BetterPriceAvailableElsewhere = 'BETTER_PRICE_AVAILABLE_ELSEWHERE',
  /** Can no longer afford it */
  CanNoLongerAffordIt = 'CAN_NO_LONGER_AFFORD_IT',
  /** Delivery date too far */
  DeliveryDateTooFar = 'DELIVERY_DATE_TOO_FAR',
  /** Missed estimated delivery date */
  MissedEstimatedDeliveryDate = 'MISSED_ESTIMATED_DELIVERY_DATE',
  /** Moving abroad */
  MovingAbroad = 'MOVING_ABROAD',
  /** Not happy with a lease */
  NotHappyWithALease = 'NOT_HAPPY_WITH_A_LEASE',
  /** Price too high */
  PriceTooHigh = 'PRICE_TOO_HIGH',
  /** Want to get a different device from another provider */
  WantToGetADifferentDeviceFromAnotherProvider = 'WANT_TO_GET_A_DIFFERENT_DEVICE_FROM_ANOTHER_PROVIDER',
}

export type CancelOrderReasonTypeEnumValue = {
  __typename?: 'CancelOrderReasonTypeEnumValue';
  displayName: Scalars['String']['output'];
  enum: CancelOrderReasonType;
};

/** Autogenerated return type of CancelSubscriptionTermination. */
export type CancelSubscriptionTerminationPayload = {
  __typename?: 'CancelSubscriptionTerminationPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean']['output'];
};

export type CancellationReason = {
  __typename?: 'CancellationReason';
  subreasons?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CardDetails = {
  __typename?: 'CardDetails';
  brand?: Maybe<Scalars['String']['output']>;
  expiryMonth?: Maybe<Scalars['Int']['output']>;
  expiryYear?: Maybe<Scalars['Int']['output']>;
  funding?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
};

export type Checkout = Timestamps & {
  __typename?: 'Checkout';
  accepted: Scalars['Boolean']['output'];
  address?: Maybe<Address>;
  analyticsId: Scalars['String']['output'];
  bankingConnection?: Maybe<BankingConnection>;
  canAddTradeIn: Scalars['Boolean']['output'];
  costSummary: CheckoutCostSummary;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer?: Maybe<Customer>;
  customerInfo?: Maybe<CheckoutCustomerInfo>;
  decision?: Maybe<CheckoutDecision>;
  experiments?: Maybe<Array<CheckoutExperiment>>;
  expired: Scalars['Boolean']['output'];
  /** The checkout reserves the items until this time */
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  forOrganization?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  incomingPayments: Array<IncomingPayment>;
  incompleteAttributes?: Maybe<Array<ValidationError>>;
  initialPaymentMethodRequired: Scalars['Boolean']['output'];
  items: Array<CheckoutItem>;
  knownDeliveryDates: Scalars['Boolean']['output'];
  merchant: Merchant;
  order?: Maybe<Order>;
  organization?: Maybe<Organization>;
  organizationInfo?: Maybe<CheckoutOrganizationInfo>;
  organizationMemberInfo?: Maybe<CheckoutOrganizationMemberInfo>;
  paymentInfo?: Maybe<CheckoutPaymentInfo>;
  previousAddress?: Maybe<Address>;
  recentChangeOfAddress?: Maybe<Scalars['Boolean']['output']>;
  recentChangeOfCountry?: Maybe<Scalars['Boolean']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
  reference: Scalars['String']['output'];
  referrer?: Maybe<Referrer>;
  state: CheckoutState;
  termsAndConditionsAcceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  token: Scalars['String']['output'];
  tradeInTermsAndConditionsAcceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  tradeIns: Array<TradeIn>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CheckoutAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countryIsoAlpha2?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  line3?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
};

export type CheckoutCostSummary = {
  __typename?: 'CheckoutCostSummary';
  merchantPrice?: Maybe<TaxableAmount>;
  recurring: CheckoutRecurringCostSummary;
  recurringPeriod: RecurringPeriod;
  termLength: Scalars['Int']['output'];
};

export type CheckoutCustomerInfo = Timestamps & {
  __typename?: 'CheckoutCustomerInfo';
  checkout: Checkout;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['ISO8601Date']['output']>;
  /** Example: 'alex.doe@example.com' */
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  marketingOptInAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  /** Example: '+447123456789' */
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CheckoutCustomerInfoInput = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  marketingOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  marketingReferralSource?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobilePhoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CheckoutDecision = Timestamps & {
  __typename?: 'CheckoutDecision';
  checkout: Checkout;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  outcome: CheckoutDecisionOutcome;
  outcomeReason?: Maybe<Scalars['String']['output']>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum CheckoutDecisionOutcome {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Referred = 'REFERRED',
  UserActionRequired = 'USER_ACTION_REQUIRED',
}

export type CheckoutDecisionReport = {
  __typename?: 'CheckoutDecisionReport';
  address?: Maybe<Address>;
  customerInfo?: Maybe<Customer>;
  merchantReference?: Maybe<Scalars['String']['output']>;
  outcome?: Maybe<CheckoutDecisionReportOutcome>;
  outcomeDecidedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
};

export enum CheckoutDecisionReportOutcome {
  /** The checkout has a failed outcome decision */
  Failure = 'failure',
  /** The checkout has a successful outcome decision */
  Success = 'success',
}

export type CheckoutDeviceInfoInput = {
  fingerprint?: InputMaybe<Scalars['String']['input']>;
};

export type CheckoutExperiment = {
  __typename?: 'CheckoutExperiment';
  name: Scalars['String']['output'];
  variation: Scalars['String']['output'];
};

export type CheckoutItem = Timestamps & {
  __typename?: 'CheckoutItem';
  availableCostSummaries?: Maybe<Array<VariantCostSummary>>;
  costSummary: CheckoutItemCostSummary;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Estimated delivery date range */
  deliveryEstimatedBetween?: Maybe<DateRange>;
  /** Expected delivery date range */
  deliveryExpectedBetween?: Maybe<DateRange>;
  feeSummary: CheckoutItemFeeSummary;
  hireAgreement?: Maybe<HireAgreement>;
  id: Scalars['ID']['output'];
  originSubscription?: Maybe<Subscription>;
  pricePlan: PricePlan;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  variant: Variant;
};

export type CheckoutItemCostSummary = {
  __typename?: 'CheckoutItemCostSummary';
  country: Country;
  /** Example: 'GBP' */
  currencyCode: Scalars['String']['output'];
  feeSummary: PricePlanFeeSummary;
  id: Scalars['ID']['output'];
  includesInsurance: Scalars['Boolean']['output'];
  initial: PricePlanInitialCostSummary;
  lastRecurringPeriod: Scalars['Int']['output'];
  merchantPrice?: Maybe<TaxableAmount>;
  recurring: PricePlanRecurringCostSummary;
  recurringPeriod: RecurringPeriod;
  termLength: Scalars['Int']['output'];
  variant?: Maybe<Variant>;
};

export type CheckoutItemFeeSummary = FeeSummary & {
  __typename?: 'CheckoutItemFeeSummary';
  earlyNonReturnFee?: Maybe<Money>;
  earlyNonReturnFeeChargeLength: Scalars['Int']['output'];
  endOfTermNonReturnFee?: Maybe<Money>;
  gradeBDamageFee?: Maybe<Money>;
  gradeBEarlyDamageFee?: Maybe<Money>;
  gradeCDamageFee?: Maybe<Money>;
  gradeCEarlyDamageFee?: Maybe<Money>;
  gradeDDamageFee?: Maybe<Money>;
  gradeDEarlyDamageFee?: Maybe<Money>;
  gradeEDamageFee?: Maybe<Money>;
  gradeEEarlyDamageFee?: Maybe<Money>;
  gradeFDamageFee?: Maybe<Money>;
  gradeFEarlyDamageFee?: Maybe<Money>;
  nonReturnFee?: Maybe<Money>;
  nonReturnFeeChargeLength: Scalars['Int']['output'];
};

export type CheckoutOrganizationInfo = {
  __typename?: 'CheckoutOrganizationInfo';
  deliveryAddress?: Maybe<Address>;
  foundedOn?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  numberOfEmployees?: Maybe<OrganizationNumberOfEmployeesEnumValue>;
  registeredAddress?: Maybe<Address>;
  registrationReference?: Maybe<Scalars['String']['output']>;
  structureType?: Maybe<OrganizationStructureTypeEnumValue>;
};

export type CheckoutOrganizationMemberInfo = {
  __typename?: 'CheckoutOrganizationMemberInfo';
  id: Scalars['ID']['output'];
  role?: Maybe<OrganizationMemberRoleEnumValue>;
};

export type CheckoutPaymentInfo = Timestamps & {
  __typename?: 'CheckoutPaymentInfo';
  /** The name of the account holder */
  bankAccountName?: Maybe<Scalars['String']['output']>;
  /** Masked number, example: ******78 */
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  /** The type of bank account */
  bankAccountType?: Maybe<BankAccountType>;
  /** Masked number, example: ****56 */
  bankBranchIdentifier?: Maybe<Scalars['String']['output']>;
  /** The name of the bank organization */
  bankName?: Maybe<Scalars['String']['output']>;
  cardDetails?: Maybe<CardDetails>;
  checkout: Checkout;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  directDebitMandateAuthorised: Scalars['Boolean']['output'];
  directDebitMandateAuthorisedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  securityDepositAmount?: Maybe<Money>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CheckoutPaymentInfoInput = {
  bankAccountName?: InputMaybe<Scalars['String']['input']>;
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankAccountType?: InputMaybe<BankAccountType>;
  bankBranchIdentifier?: InputMaybe<Scalars['String']['input']>;
  directDebitMandateAuthorised?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CheckoutRecurringCostSummary = {
  __typename?: 'CheckoutRecurringCostSummary';
  totalAmount: TaxableAmount;
};

export enum CheckoutState {
  Abandoned = 'ABANDONED',
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Started = 'STARTED',
  Submitted = 'SUBMITTED',
}

export enum CheckoutTrackingCategoryType {
  /** Ads */
  Ads = 'ADS',
  /** Affiliate */
  Affiliate = 'AFFILIATE',
  /** Analytics */
  Analytics = 'ANALYTICS',
  /** Direct application */
  Direct = 'DIRECT',
  /** Display advertising */
  Display = 'DISPLAY',
  /** Email */
  Email = 'EMAIL',
  /** Experiments */
  Experiments = 'EXPERIMENTS',
  /** Organic search */
  OrganicSearch = 'ORGANIC_SEARCH',
  /** Paid search */
  PaidSearch = 'PAID_SEARCH',
  /** Paid social */
  PaidSocial = 'PAID_SOCIAL',
  /** Referral */
  Referral = 'REFERRAL',
  /** Social */
  Social = 'SOCIAL',
}

export type ClientAuthenticationToken = {
  __typename?: 'ClientAuthenticationToken';
  expiresAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['String']['output'];
};

export type Country = Node &
  Timestamps & {
    __typename?: 'Country';
    /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** Example: 'GBP' */
    currencyCode: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    isoAlpha2: Scalars['String']['output'];
    isoAlpha3: Scalars['String']['output'];
    name: Scalars['String']['output'];
    /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

/** Autogenerated return type of CreateAcceptableData. */
export type CreateAcceptableDataPayload = {
  __typename?: 'CreateAcceptableDataPayload';
  bankAccount: BankAccount;
  dateOfBirth: Scalars['ISO8601Date']['output'];
  email: Scalars['String']['output'];
  invitationCode: Scalars['String']['output'];
  merchantCheckoutPayload: Scalars['String']['output'];
  merchantPriceInputRequired: Scalars['Boolean']['output'];
  phoneNumber: Scalars['String']['output'];
  previewMerchantCheckoutItemPayload: Scalars['String']['output'];
  previewMerchantCheckoutPayload: Scalars['String']['output'];
  userToken: Scalars['String']['output'];
};

/** Autogenerated return type of CreateAcceptableData. */
export type CreateAcceptableDataPayloadMerchantCheckoutPayloadArgs = {
  merchantId: Scalars['ID']['input'];
  variantId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreateAcceptableData. */
export type CreateAcceptableDataPayloadMerchantPriceInputRequiredArgs = {
  merchantId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAcceptableData. */
export type CreateAcceptableDataPayloadPreviewMerchantCheckoutItemPayloadArgs = {
  merchantId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAcceptableData. */
export type CreateAcceptableDataPayloadPreviewMerchantCheckoutPayloadArgs = {
  merchantId: Scalars['ID']['input'];
  variantId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreateAcceptableData. */
export type CreateAcceptableDataPayloadUserTokenArgs = {
  merchantId?: InputMaybe<Scalars['ID']['input']>;
  subscriptionOptions?: InputMaybe<Array<AcceptableDataSubscriptionOption>>;
};

/** Autogenerated return type of CreateBlockRequest. */
export type CreateBlockRequestPayload = {
  __typename?: 'CreateBlockRequestPayload';
  asset?: Maybe<Asset>;
};

/** Arguments for creating a Checkout */
export type CreateCheckoutInput = {
  forOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  invitationCode?: InputMaybe<Scalars['String']['input']>;
  items: Array<CreateCheckoutItemInput>;
  merchantId: Scalars['ID']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  privacyPolicyAccepted: Scalars['Boolean']['input'];
};

export type CreateCheckoutItemInput = {
  costSummaryId?: InputMaybe<Scalars['ID']['input']>;
  originSubscriptionId?: InputMaybe<Scalars['ID']['input']>;
  variantId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateCheckoutPayment. */
export type CreateCheckoutPaymentPayload = {
  __typename?: 'CreateCheckoutPaymentPayload';
  clientSecret: Scalars['String']['output'];
};

export type CreateCheckoutResult = {
  __typename?: 'CreateCheckoutResult';
  checkout?: Maybe<Checkout>;
  errors: Array<ValidationError>;
};

/** Arguments for creating a Checkout Trade-in */
export type CreateCheckoutTradeInInput = {
  gradeId: Scalars['ID']['input'];
  gradingAnswerIds: Array<Scalars['ID']['input']>;
  variantId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateCheckoutTradeIn. */
export type CreateCheckoutTradeInPayload = {
  __typename?: 'CreateCheckoutTradeInPayload';
  errors: Array<ValidationError>;
  tradeIn?: Maybe<TradeIn>;
};

/** Autogenerated return type of CreateCheckoutUnsignedHireAgreement. */
export type CreateCheckoutUnsignedHireAgreementPayload = {
  __typename?: 'CreateCheckoutUnsignedHireAgreementPayload';
  success: Scalars['Boolean']['output'];
};

export type CreateInsuranceClaimInput = {
  insurancePolicyId: Scalars['ID']['input'];
  reason: InsuranceClaimReasonType;
};

/** Autogenerated return type of CreateInsuranceClaim. */
export type CreateInsuranceClaimPayload = {
  __typename?: 'CreateInsuranceClaimPayload';
  errors: Array<ValidationError>;
  insuranceClaim?: Maybe<InsuranceClaim>;
};

export type Customer = Timestamps & {
  __typename?: 'Customer';
  address?: Maybe<Address>;
  analyticsId: Scalars['String']['output'];
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['ISO8601Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  marketingOptInAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  messagingDetails?: Maybe<CustomerMessagingDetails>;
  middleName?: Maybe<Scalars['String']['output']>;
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  orders: Array<Order>;
  preApproval: CustomerPreApproval;
  referralInfo: ReferralInfo;
  subscriptions: Array<Subscription>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userToken?: Maybe<Scalars['String']['output']>;
};

export type CustomerIntercomDetails = {
  __typename?: 'CustomerIntercomDetails';
  appId: Scalars['String']['output'];
  userHash: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type CustomerMessagingDetails = {
  __typename?: 'CustomerMessagingDetails';
  intercom?: Maybe<CustomerIntercomDetails>;
};

export type CustomerPreApproval = {
  __typename?: 'CustomerPreApproval';
  checkout?: Maybe<Checkout>;
  recurringTaxableAmount?: Maybe<TaxableAmount>;
  successful: Scalars['Boolean']['output'];
};

export type DateRange = {
  __typename?: 'DateRange';
  max: Scalars['ISO8601Date']['output'];
  min: Scalars['ISO8601Date']['output'];
};

export type Feature = {
  __typename?: 'Feature';
  domain: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
};

export type FeeSummary = {
  earlyNonReturnFee?: Maybe<Money>;
  earlyNonReturnFeeChargeLength: Scalars['Int']['output'];
  endOfTermNonReturnFee?: Maybe<Money>;
  gradeBDamageFee?: Maybe<Money>;
  gradeBEarlyDamageFee?: Maybe<Money>;
  gradeCDamageFee?: Maybe<Money>;
  gradeCEarlyDamageFee?: Maybe<Money>;
  gradeDDamageFee?: Maybe<Money>;
  gradeDEarlyDamageFee?: Maybe<Money>;
  gradeEDamageFee?: Maybe<Money>;
  gradeEEarlyDamageFee?: Maybe<Money>;
  gradeFDamageFee?: Maybe<Money>;
  gradeFEarlyDamageFee?: Maybe<Money>;
  nonReturnFee?: Maybe<Money>;
  nonReturnFeeChargeLength: Scalars['Int']['output'];
};

/** Arguments for querying manufacturers, products and variants for checkout */
export type ForCheckoutInput = {
  onlyAvailable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FulfillmentItem = Timestamps & {
  __typename?: 'FulfillmentItem';
  courierName?: Maybe<Scalars['String']['output']>;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  deliveredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dispatchedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  expectedDeliveryAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  trackingReference?: Maybe<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type HireAgreement = {
  __typename?: 'HireAgreement';
  nobodySigned?: Maybe<HireAgreementDocument>;
};

export type HireAgreementDocument = {
  __typename?: 'HireAgreementDocument';
  htmlContent?: Maybe<Scalars['String']['output']>;
};

export type Image = Node &
  Timestamps & {
    __typename?: 'Image';
    /** Example: 'front-1' */
    alt?: Maybe<Scalars['String']['output']>;
    /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
    createdAt: Scalars['ISO8601DateTime']['output'];
    id: Scalars['ID']['output'];
    position: Scalars['Int']['output'];
    /** Example: 'front' */
    tag?: Maybe<Scalars['String']['output']>;
    /** Example: 'Front 1' */
    title?: Maybe<Scalars['String']['output']>;
    /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    /** Example: 'https://image-server.com/image.jpg' */
    url: Scalars['String']['output'];
  };

export type IncomingPayment = Timestamps & {
  __typename?: 'IncomingPayment';
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  items: Array<IncomingPaymentItem>;
  partnerReferences: Array<PartnerReference>;
  partnerSlug?: Maybe<Scalars['String']['output']>;
  state: IncomingPaymentState;
  totalAmount: Money;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type IncomingPaymentItem = Timestamps & {
  __typename?: 'IncomingPaymentItem';
  amount: Money;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum IncomingPaymentState {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Prepared = 'PREPARED',
  Referred = 'REFERRED',
  Refunded = 'REFUNDED',
  Requested = 'REQUESTED',
}

export type InsuranceClaim = Timestamps & {
  __typename?: 'InsuranceClaim';
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  externalReference?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  reason: InsuranceClaimReasonTypeEnumValue;
  state: InsuranceClaimStateType;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum InsuranceClaimReasonType {
  /** Accidental Damage */
  AccidentalDamage = 'ACCIDENTAL_DAMAGE',
  /** Liquid Damage */
  LiquidDamage = 'LIQUID_DAMAGE',
  /** Loss */
  Loss = 'LOSS',
  /** Theft */
  Theft = 'THEFT',
}

export type InsuranceClaimReasonTypeEnumValue = {
  __typename?: 'InsuranceClaimReasonTypeEnumValue';
  displayName: Scalars['String']['output'];
  enum: InsuranceClaimReasonType;
};

export enum InsuranceClaimStateType {
  /** Expired */
  Expired = 'EXPIRED',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Settled */
  Settled = 'SETTLED',
  /** Started */
  Started = 'STARTED',
}

export type InsurancePolicy = Timestamps & {
  __typename?: 'InsurancePolicy';
  claimUrl?: Maybe<Scalars['String']['output']>;
  claims: Array<InsuranceClaim>;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Manufacturer = Node &
  Timestamps & {
    __typename?: 'Manufacturer';
    /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
    createdAt: Scalars['ISO8601DateTime']['output'];
    displayName?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    products?: Maybe<Array<Product>>;
    slug: Scalars['String']['output'];
    /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type ManufacturerProductsArgs = {
  forCheckout?: InputMaybe<Scalars['Boolean']['input']>;
  forCheckoutInput?: InputMaybe<ForCheckoutInput>;
  forTradeIn?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  merchantId: Scalars['ID']['input'];
  productCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** The connection type for Manufacturer. */
export type ManufacturerConnection = {
  __typename?: 'ManufacturerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ManufacturerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Manufacturer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ManufacturerEdge = {
  __typename?: 'ManufacturerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Manufacturer>;
};

export type Merchant = {
  __typename?: 'Merchant';
  brandingTheme: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  features: Array<Feature>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type Money = {
  __typename?: 'Money';
  currencyCode: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  formattedValue: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  valueInSubunit: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Issue a client auth token that can be used to perform operations on this schema */
  authenticateClient?: Maybe<AuthenticateClientPayload>;
  /** Cancels a placed, but not yet dispatched, order */
  cancelOrder?: Maybe<CancelOrderPayload>;
  /** Cancels a started, but not yet completed, termination. */
  cancelSubscriptionTermination?: Maybe<CancelSubscriptionTerminationPayload>;
  /** Creates valid data that can be used for the checkout process. */
  createAcceptableData?: Maybe<CreateAcceptableDataPayload>;
  /** [LOGGED-IN] Create a block request for the given asset ID */
  createBlockRequest?: Maybe<CreateBlockRequestPayload>;
  /**
   * Create a checkout object
   *
   * When the `X-User-Token` header is supplied it will authorize the user and associate it with the
   * new checkout.
   */
  createCheckout?: Maybe<CreateCheckoutResult>;
  /**
   * Create a new setup intent for the given checkout token or for the active checkout of the
   * current user
   */
  createCheckoutPayment?: Maybe<CreateCheckoutPaymentPayload>;
  /** Create a trade-in for the given checkout token */
  createCheckoutTradeIn?: Maybe<CreateCheckoutTradeInPayload>;
  /** Generate an unsigned hire agreement document for a started checkout */
  createCheckoutUnsignedHireAgreement?: Maybe<CreateCheckoutUnsignedHireAgreementPayload>;
  /** [LOGGED-IN] Create a new insurance claim for the given insurance policy ID */
  createInsuranceClaim?: Maybe<CreateInsuranceClaimPayload>;
  /** Create a checkout for a merchant */
  createMerchantCheckout?: Maybe<CreateCheckoutResult>;
  /** Make necessary preparations for any user that matches the provided email */
  prepareForUserLogin?: Maybe<PrepareForUserLoginPayload>;
  /** Process incoming payment */
  processIncomingPayment?: Maybe<ProcessIncomingPaymentPayload>;
  /** Remove a trade-in for the checkout with the provided token */
  removeCheckoutTradeIn?: Maybe<RemoveCheckoutTradeInPayload>;
  /** Restarts a submitted checkout */
  restartCheckout?: Maybe<RestartCheckoutPayload>;
  /** Resumes an abandoned checkout */
  resumeCheckout?: Maybe<ResumeCheckoutPayload>;
  /** Searches for organizations matching the provided term */
  searchOrganizationInfo?: Maybe<Array<SearchOrganizationInfoResult>>;
  /** Starts an asset return for a subscription */
  startAssetReturn?: Maybe<StartAssetReturnPayload>;
  /** Starts a banking connection */
  startBankingConnection?: Maybe<StartBankingConnectionPayload>;
  /** Start process of paying a checkout security deposit */
  startCheckoutSecurityDepositPayment?: Maybe<StartCheckoutSecurityDepositPaymentPayload>;
  /** Start process of paying subscription arrears */
  startSubscriptionArrearsPayment?: Maybe<StartSubscriptionArrearsPaymentPayload>;
  /** Start process of terminating a subscription */
  startSubscriptionTermination?: Maybe<StartSubscriptionTerminationPayload>;
  /** Submits a checkout */
  submitCheckout?: Maybe<SubmitCheckoutPayload>;
  /** Changes an item of an existing checkout, with a new item for the given input */
  swapCheckoutItem?: Maybe<SwapCheckoutItemPayload>;
  trackCheckout?: Maybe<TrackCheckoutPayload>;
  /**
   * Updates an existing checkout with the provided information.
   *
   * **NOTE:** At least one of `checkoutToken` input or `X-User-Token` header needs to be supplied.
   *
   * - When only the `checkoutToken` input is supplied, the guest user will update the matching
   *   checkout.
   * - When only the `X-User-Token` header is supplied, the authenticated user will update the
   *   associated checkout (if they have one).
   * - When both are supplied, the authenticated user will be associated with the matching checkout
   *   and update it. If the matching checkout already has an associated user that is not the
   *   authenticated user, the mutation will be rejected.
   */
  updateCheckout?: Maybe<UpdateCheckoutPayload>;
  /** Update an order of a merchant */
  updateMerchantOrder?: Maybe<UpdateMerchantOrderPayload>;
  /** Verifies a banking connection */
  verifyBankingConnection?: Maybe<VerifyBankingConnectionPayload>;
};

export type MutationAuthenticateClientArgs = {
  clientId: Scalars['String']['input'];
};

export type MutationCancelOrderArgs = {
  customerNotes?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
  reason: CancelOrderReasonType;
};

export type MutationCancelSubscriptionTerminationArgs = {
  subscriptionId: Scalars['ID']['input'];
};

export type MutationCreateBlockRequestArgs = {
  assetId: Scalars['ID']['input'];
};

export type MutationCreateCheckoutArgs = {
  input: CreateCheckoutInput;
};

export type MutationCreateCheckoutPaymentArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateCheckoutTradeInArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
  input: CreateCheckoutTradeInInput;
};

export type MutationCreateCheckoutUnsignedHireAgreementArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateInsuranceClaimArgs = {
  input: CreateInsuranceClaimInput;
};

export type MutationCreateMerchantCheckoutArgs = {
  merchantId: Scalars['ID']['input'];
  payload: Scalars['String']['input'];
};

export type MutationPrepareForUserLoginArgs = {
  email: Scalars['String']['input'];
};

export type MutationProcessIncomingPaymentArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  incomingPaymentId: Scalars['ID']['input'];
};

export type MutationRemoveCheckoutTradeInArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
  tradeInId: Scalars['ID']['input'];
};

export type MutationRestartCheckoutArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationResumeCheckoutArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSearchOrganizationInfoArgs = {
  checkoutToken: Scalars['String']['input'];
  term: Scalars['String']['input'];
};

export type MutationStartAssetReturnArgs = {
  subscriptionId: Scalars['ID']['input'];
};

export type MutationStartBankingConnectionArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationStartCheckoutSecurityDepositPaymentArgs = {
  checkoutToken: Scalars['String']['input'];
};

export type MutationStartSubscriptionArrearsPaymentArgs = {
  subscriptionId: Scalars['ID']['input'];
};

export type MutationStartSubscriptionTerminationArgs = {
  customerNotes?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type MutationSubmitCheckoutArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSwapCheckoutItemArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
  input: SwapCheckoutItemInput;
};

export type MutationTrackCheckoutArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
  inputs: Array<UpdateCheckoutTrackingInput>;
};

export type MutationUpdateCheckoutArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
  input: UpdateCheckoutInput;
};

export type MutationUpdateMerchantOrderArgs = {
  merchantId: Scalars['ID']['input'];
  payload: Scalars['String']['input'];
};

export type MutationVerifyBankingConnectionArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

export type OptionType = Node &
  Timestamps & {
    __typename?: 'OptionType';
    /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** Example: 'Colour' */
    displayName?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /**
     * Example: 'colour'
     *
     * @deprecated Use the slug field instead.
     */
    name: Scalars['String']['output'];
    optionValues?: Maybe<Array<OptionValue>>;
    position: Scalars['Int']['output'];
    products?: Maybe<Array<Product>>;
    /** Example: 'colour' */
    slug: Scalars['String']['output'];
    /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type OptionValue = Node &
  Timestamps & {
    __typename?: 'OptionValue';
    /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** Example: 'Silver' */
    displayName: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    /** @deprecated Use the slug field instead. */
    name: Scalars['String']['output'];
    optionType: OptionType;
    position: Scalars['Int']['output'];
    /** Example: '#DDDFDE' */
    raw?: Maybe<Scalars['String']['output']>;
    slug: Scalars['String']['output'];
    /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    variants?: Maybe<Array<Variant>>;
  };

export type Order = Timestamps & {
  __typename?: 'Order';
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  directCancellationSupported: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  items: Array<OrderItem>;
  knownDeliveryDates: Scalars['Boolean']['output'];
  merchant: Merchant;
  merchantReference?: Maybe<Scalars['String']['output']>;
  reference: Scalars['String']['output'];
  state: OrderState;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type OrderItem = Timestamps & {
  __typename?: 'OrderItem';
  availableCostSummaries?: Maybe<Array<VariantCostSummary>>;
  costSummary: CheckoutItemCostSummary;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Estimated delivery date range */
  deliveryEstimatedBetween?: Maybe<DateRange>;
  /** Expected delivery date range */
  deliveryExpectedBetween?: Maybe<DateRange>;
  feeSummary: CheckoutItemFeeSummary;
  fulfillmentItem?: Maybe<FulfillmentItem>;
  hireAgreement?: Maybe<HireAgreement>;
  id: Scalars['ID']['output'];
  originSubscription?: Maybe<Subscription>;
  pricePlan: PricePlan;
  subscription?: Maybe<Subscription>;
  timeline: Array<TimelineEvent>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  variant: Variant;
};

export enum OrderState {
  Cancelled = 'CANCELLED',
  Placed = 'PLACED',
}

export type Organization = {
  __typename?: 'Organization';
  deliveryAddress?: Maybe<Address>;
  foundedOn?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  numberOfEmployees?: Maybe<OrganizationNumberOfEmployeesEnumValue>;
  registeredAddress?: Maybe<Address>;
  registrationReference?: Maybe<Scalars['String']['output']>;
  structureType: OrganizationStructureTypeEnumValue;
};

export enum OrganizationMemberRole {
  /** Director */
  Director = 'DIRECTOR',
  /** Employee */
  Employee = 'EMPLOYEE',
  /** Owner */
  Owner = 'OWNER',
  /** Partner */
  Partner = 'PARTNER',
}

export type OrganizationMemberRoleEnumValue = {
  __typename?: 'OrganizationMemberRoleEnumValue';
  displayName: Scalars['String']['output'];
  enum: OrganizationMemberRole;
};

export enum OrganizationNumberOfEmployees {
  /** 50-249 */
  FiftyToTwoHundredFortyNine = 'FIFTY_TO_TWO_HUNDRED_FORTY_NINE',
  /** 5-9 */
  FiveToNine = 'FIVE_TO_NINE',
  /** 1-4 */
  OneToFour = 'ONE_TO_FOUR',
  /** 10-19 */
  TenToNineteen = 'TEN_TO_NINETEEN',
  /** 30-49 */
  ThirtyToFortyNine = 'THIRTY_TO_FORTY_NINE',
  /** 20-29 */
  TwentyToTwentyNine = 'TWENTY_TO_TWENTY_NINE',
  /** 250+ */
  TwoHundredFiftyPlus = 'TWO_HUNDRED_FIFTY_PLUS',
  /** 0 */
  Zero = 'ZERO',
}

export type OrganizationNumberOfEmployeesEnumValue = {
  __typename?: 'OrganizationNumberOfEmployeesEnumValue';
  displayName: Scalars['String']['output'];
  enum: OrganizationNumberOfEmployees;
};

export enum OrganizationStructureType {
  /** Limited company */
  LimitedCompany = 'LIMITED_COMPANY',
  /** Limited liability partnership */
  LimitedLiabilityPartnership = 'LIMITED_LIABILITY_PARTNERSHIP',
  /** Other */
  Other = 'OTHER',
  /** Partnership with four or more partners */
  PartnershipFourOrMore = 'PARTNERSHIP_FOUR_OR_MORE',
  /** Partnership with three or fewer partners */
  PartnershipThreeOrFewer = 'PARTNERSHIP_THREE_OR_FEWER',
  /** Sole trader */
  SoleTrader = 'SOLE_TRADER',
}

export type OrganizationStructureTypeEnumValue = {
  __typename?: 'OrganizationStructureTypeEnumValue';
  displayName: Scalars['String']['output'];
  enum: OrganizationStructureType;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PartnerReference = Timestamps & {
  __typename?: 'PartnerReference';
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  partnerSlug: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  referenceType?: Maybe<Scalars['String']['output']>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated return type of PrepareForUserLogin. */
export type PrepareForUserLoginPayload = {
  __typename?: 'PrepareForUserLoginPayload';
  success: Scalars['Boolean']['output'];
};

export type PreviewCheckout = {
  __typename?: 'PreviewCheckout';
  costSummary: CheckoutCostSummary;
  items: Array<PreviewCheckoutItem>;
  merchant?: Maybe<Merchant>;
};

export type PreviewCheckoutItem = {
  __typename?: 'PreviewCheckoutItem';
  costSummary: VariantCostSummary;
  merchant?: Maybe<Merchant>;
  variant?: Maybe<Variant>;
};

export type PreviewCheckoutItemResult = {
  __typename?: 'PreviewCheckoutItemResult';
  checkoutItem?: Maybe<PreviewCheckoutItem>;
  errors: Array<ValidationError>;
};

export type PreviewMerchantCheckout = {
  __typename?: 'PreviewMerchantCheckout';
  checkout?: Maybe<PreviewCheckout>;
  errors: Array<ValidationError>;
};

export type PricePlan = Node &
  Timestamps & {
    __typename?: 'PricePlan';
    country: Country;
    /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
    createdAt: Scalars['ISO8601DateTime']['output'];
    /** Example: 'GBP' */
    currencyCode: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    includesInsurance: Scalars['Boolean']['output'];
    lastRecurringPeriod: Scalars['Int']['output'];
    /** Example: '35.99pm - 24 months' */
    name: Scalars['String']['output'];
    recurringPeriod: RecurringPeriod;
    termLength: Scalars['Int']['output'];
    /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    variants?: Maybe<VariantConnection>;
  };

export type PricePlanVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  condition?: InputMaybe<VariantCondition>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PricePlanFeeSummary = FeeSummary & {
  __typename?: 'PricePlanFeeSummary';
  earlyNonReturnFee?: Maybe<Money>;
  earlyNonReturnFeeChargeLength: Scalars['Int']['output'];
  endOfTermNonReturnFee?: Maybe<Money>;
  gradeBDamageFee?: Maybe<Money>;
  gradeBEarlyDamageFee?: Maybe<Money>;
  gradeCDamageFee?: Maybe<Money>;
  gradeCEarlyDamageFee?: Maybe<Money>;
  gradeDDamageFee?: Maybe<Money>;
  gradeDEarlyDamageFee?: Maybe<Money>;
  gradeEDamageFee?: Maybe<Money>;
  gradeEEarlyDamageFee?: Maybe<Money>;
  gradeFDamageFee?: Maybe<Money>;
  gradeFEarlyDamageFee?: Maybe<Money>;
  nonReturnFee?: Maybe<Money>;
  nonReturnFeeChargeLength: Scalars['Int']['output'];
};

export type PricePlanInitialCostSummary = {
  __typename?: 'PricePlanInitialCostSummary';
  baseAmount: TaxableAmount;
  totalAmount: TaxableAmount;
};

export type PricePlanRecurringCostSummary = {
  __typename?: 'PricePlanRecurringCostSummary';
  adjustmentAmount?: Maybe<TaxableAmount>;
  baseAmount: TaxableAmount;
  endOfTermAmount: TaxableAmount;
  insuranceAmount?: Maybe<TaxableAmount>;
  totalAmount: TaxableAmount;
};

/** Autogenerated return type of ProcessIncomingPayment. */
export type ProcessIncomingPaymentPayload = {
  __typename?: 'ProcessIncomingPaymentPayload';
  incomingPayment?: Maybe<IncomingPayment>;
};

/** A product that can be added to a checkout. */
export type Product = Node &
  Timestamps & {
    __typename?: 'Product';
    /** Variants of the product are available for adding to checkouts */
    available: Scalars['Boolean']['output'];
    canAddTradeIn: Scalars['Boolean']['output'];
    category: ProductCategory;
    /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
    createdAt: Scalars['ISO8601DateTime']['output'];
    defaultImage?: Maybe<Image>;
    /** Example: 'Product name' */
    displayName?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    /** In markdown format */
    keyFeatures?: Maybe<Scalars['String']['output']>;
    lowestCostSummary?: Maybe<VariantCostSummary>;
    manufacturer: Manufacturer;
    optionTypes: Array<OptionType>;
    overview?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    /** Product is available for checkout before its release */
    preOrder: Scalars['Boolean']['output'];
    releasedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    slug: Scalars['String']['output'];
    /** In markdown format */
    specifications?: Maybe<Scalars['String']['output']>;
    /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
    updatedAt: Scalars['ISO8601DateTime']['output'];
    variantConditions: Array<VariantCondition>;
    variants: Array<Variant>;
  };

/** A product that can be added to a checkout. */
export type ProductLowestCostSummaryArgs = {
  merchantId: Scalars['ID']['input'];
};

/** A product that can be added to a checkout. */
export type ProductVariantsArgs = {
  condition?: InputMaybe<VariantCondition>;
  forCheckout?: InputMaybe<Scalars['Boolean']['input']>;
  forCheckoutInput?: InputMaybe<ForCheckoutInput>;
  forTradeIn?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  merchantId: Scalars['ID']['input'];
};

export type ProductCategory = Node &
  Timestamps & {
    __typename?: 'ProductCategory';
    /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
    createdAt: Scalars['ISO8601DateTime']['output'];
    displayName?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    slug: Scalars['String']['output'];
    /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

/** The connection type for Product. */
export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Product>;
};

export type Query = {
  __typename?: 'Query';
  /** Fetches a list of all order cancellation reasons */
  cancelOrderReasonTypes: Array<CancelOrderReasonTypeEnumValue>;
  /** Fetches a list of all subscription cancellation reasons */
  cancelSubscriptionReasonTypes: Array<CancellationReason>;
  /** Fetches the checkout for the given token */
  checkout?: Maybe<Checkout>;
  /** Fetches the checkout decision report for the given token */
  checkoutDecisionReport?: Maybe<CheckoutDecisionReport>;
  /** [LOGGED-IN] Fetches the customer of the logged in user */
  customer?: Maybe<Customer>;
  /** Fetches a list of all insurance claim reasons */
  insuranceClaimReasonTypes: Array<InsuranceClaimReasonTypeEnumValue>;
  /** Fetches a list of all manufacturers */
  manufacturers: Array<Manufacturer>;
  manufacturersConnection: ManufacturerConnection;
  /** Fetches the merchant by domain */
  merchant?: Maybe<Merchant>;
  /** Fetches all merchants */
  merchants?: Maybe<Array<Merchant>>;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  /** Fetches list of all organization member roles */
  organizationMemberRoles: Array<OrganizationMemberRoleEnumValue>;
  /** Fetches list of all values for number of employees */
  organizationNumberOfEmployees: Array<OrganizationNumberOfEmployeesEnumValue>;
  /** Fetches list of all organization structure types */
  organizationStructureTypes: Array<OrganizationStructureTypeEnumValue>;
  /** Builds a checkout preview based on merchant and JWT payload */
  previewMerchantCheckout?: Maybe<PreviewMerchantCheckout>;
  /** Builds a checkout item preview based on merchant and JWT payload */
  previewMerchantCheckoutItem: PreviewCheckoutItemResult;
  /** Fetches a list of all product categories */
  productCategories: Array<ProductCategory>;
  /** Fetches a list of all available products */
  products: Array<Product>;
  productsConnection: ProductConnection;
  /** Fetches details for the current referral program */
  referralProgram: ReferralProgram;
  /** Fetches a list of all trade-in grading questions */
  tradeInGradingQuestions: Array<TradeInGradingQuestion>;
  /** Fetches a list of all available variants */
  variants: Array<Variant>;
  variantsConnection: VariantConnection;
};

export type QueryCancelSubscriptionReasonTypesArgs = {
  subscriptionId: Scalars['ID']['input'];
};

export type QueryCheckoutArgs = {
  token: Scalars['String']['input'];
};

export type QueryCheckoutDecisionReportArgs = {
  token: Scalars['String']['input'];
};

export type QueryManufacturersArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
  forCheckout?: InputMaybe<Scalars['Boolean']['input']>;
  forCheckoutInput?: InputMaybe<ForCheckoutInput>;
  forTradeIn?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  merchantId: Scalars['ID']['input'];
  productCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryManufacturersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forCheckout?: InputMaybe<Scalars['Boolean']['input']>;
  forCheckoutInput?: InputMaybe<ForCheckoutInput>;
  forTradeIn?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  merchantId: Scalars['ID']['input'];
  productCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryMerchantArgs = {
  domain: Scalars['String']['input'];
};

export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryPreviewMerchantCheckoutArgs = {
  merchantId: Scalars['ID']['input'];
  payload: Scalars['String']['input'];
};

export type QueryPreviewMerchantCheckoutItemArgs = {
  merchantId: Scalars['ID']['input'];
  payload: Scalars['String']['input'];
};

export type QueryProductCategoriesArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
  forCheckout?: InputMaybe<Scalars['Boolean']['input']>;
  forCheckoutInput?: InputMaybe<ForCheckoutInput>;
  forTradeIn?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  merchantId: Scalars['ID']['input'];
};

export type QueryProductsArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
  forCheckout?: InputMaybe<Scalars['Boolean']['input']>;
  forCheckoutInput?: InputMaybe<ForCheckoutInput>;
  forTradeIn?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  manufacturerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  merchantId: Scalars['ID']['input'];
  productCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forCheckout?: InputMaybe<Scalars['Boolean']['input']>;
  forCheckoutInput?: InputMaybe<ForCheckoutInput>;
  forTradeIn?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manufacturerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  merchantId: Scalars['ID']['input'];
  productCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryTradeInGradingQuestionsArgs = {
  variantId: Scalars['ID']['input'];
};

export type QueryVariantsArgs = {
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
  forCheckout?: InputMaybe<Scalars['Boolean']['input']>;
  forCheckoutInput?: InputMaybe<ForCheckoutInput>;
  forTradeIn?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  merchantId: Scalars['ID']['input'];
  productIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryVariantsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forCheckout?: InputMaybe<Scalars['Boolean']['input']>;
  forCheckoutInput?: InputMaybe<ForCheckoutInput>;
  forTradeIn?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  merchantId: Scalars['ID']['input'];
  productIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum RecurringPeriod {
  /** Monthly repayment */
  Month = 'MONTH',
}

export type Referral = {
  __typename?: 'Referral';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rewardAvailableAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  state: ReferralStateTypeEnumValue;
};

export type ReferralInfo = {
  __typename?: 'ReferralInfo';
  externalReference?: Maybe<Scalars['String']['output']>;
  /** The referrals of the customer that have not been rewarded yet */
  pendingReferralCount: Scalars['Int']['output'];
  /** The referrals of the customer */
  referrals?: Maybe<Array<Referral>>;
  /** The referrals of the customer that have been rewarded */
  successfulReferralCount: Scalars['Int']['output'];
  /** The unsuccessful referrals of the customer */
  unsuccessfulReferralCount: Scalars['Int']['output'];
  /** The sharable personal referral url for customer */
  url?: Maybe<Scalars['String']['output']>;
};

export type ReferralProgram = {
  __typename?: 'ReferralProgram';
  id: Scalars['String']['output'];
  referrer?: Maybe<Referrer>;
  reward: Reward;
};

export type ReferralProgramReferrerArgs = {
  id: Scalars['String']['input'];
};

export enum ReferralStateType {
  /** Pending */
  Pending = 'PENDING',
  /** Successful */
  Successful = 'SUCCESSFUL',
  /** Unsuccessful */
  Unsuccessful = 'UNSUCCESSFUL',
}

export type ReferralStateTypeEnumValue = {
  __typename?: 'ReferralStateTypeEnumValue';
  displayName: Scalars['String']['output'];
  enum: ReferralStateType;
};

export type Referrer = {
  __typename?: 'Referrer';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

/** Autogenerated return type of RemoveCheckoutTradeIn. */
export type RemoveCheckoutTradeInPayload = {
  __typename?: 'RemoveCheckoutTradeInPayload';
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of RestartCheckout. */
export type RestartCheckoutPayload = {
  __typename?: 'RestartCheckoutPayload';
  checkout?: Maybe<Checkout>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of ResumeCheckout. */
export type ResumeCheckoutPayload = {
  __typename?: 'ResumeCheckoutPayload';
  checkout?: Maybe<Checkout>;
  errors: Array<ValidationError>;
};

export type Reward = {
  __typename?: 'Reward';
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** Description of the reward for the person that gets referred */
  referralDescription?: Maybe<Scalars['String']['output']>;
  /** Description of the reward for the person that makes the referral */
  referrerDescription?: Maybe<Scalars['String']['output']>;
};

export type SearchOrganizationInfoResult = {
  __typename?: 'SearchOrganizationInfoResult';
  addressSnippet: Scalars['String']['output'];
  errors: Array<ValidationError>;
  foundedOn: Scalars['String']['output'];
  name: Scalars['String']['output'];
  registrationReference: Scalars['String']['output'];
  state: Scalars['String']['output'];
  structureType: OrganizationStructureTypeEnumValue;
};

/** Autogenerated return type of StartAssetReturn. */
export type StartAssetReturnPayload = {
  __typename?: 'StartAssetReturnPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of StartBankingConnection. */
export type StartBankingConnectionPayload = {
  __typename?: 'StartBankingConnectionPayload';
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of StartCheckoutSecurityDepositPayment. */
export type StartCheckoutSecurityDepositPaymentPayload = {
  __typename?: 'StartCheckoutSecurityDepositPaymentPayload';
  incomingPayment?: Maybe<IncomingPayment>;
};

/** Autogenerated return type of StartSubscriptionArrearsPayment. */
export type StartSubscriptionArrearsPaymentPayload = {
  __typename?: 'StartSubscriptionArrearsPaymentPayload';
  incomingPayment?: Maybe<IncomingPayment>;
};

/** Autogenerated return type of StartSubscriptionTermination. */
export type StartSubscriptionTerminationPayload = {
  __typename?: 'StartSubscriptionTerminationPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of SubmitCheckout. */
export type SubmitCheckoutPayload = {
  __typename?: 'SubmitCheckoutPayload';
  checkout?: Maybe<Checkout>;
  errors: Array<ValidationError>;
};

export type Subscription = Timestamps & {
  __typename?: 'Subscription';
  activeAsset?: Maybe<Asset>;
  arrearsAmount?: Maybe<Money>;
  assetReturns?: Maybe<Array<AssetReturn>>;
  cancellationPeriodCategory: SubscriptionCancellationPeriodCategory;
  costSummary: SubscriptionCostSummary;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  feeSummary: SubscriptionFeeSummary;
  id: Scalars['ID']['output'];
  includesInsurance: Scalars['Boolean']['output'];
  incomingPayments: Array<IncomingPayment>;
  insurancePolicy?: Maybe<InsurancePolicy>;
  lastScheduledPaymentOn?: Maybe<Scalars['ISO8601Date']['output']>;
  nextPaymentScheduleEvent?: Maybe<SubscriptionPaymentScheduleEvent>;
  paymentInfo?: Maybe<SubscriptionPaymentInfo>;
  paymentSchedule: Array<SubscriptionPaymentScheduleEvent>;
  pricePlan: PricePlan;
  startedOn?: Maybe<Scalars['ISO8601Date']['output']>;
  state: SubscriptionState;
  transactions: Array<SubscriptionTransaction>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
  upgrade: SubscriptionUpgrade;
};

export enum SubscriptionCancellationPeriodCategory {
  CoolingOff = 'COOLING_OFF',
  DuringInitialLeaseTerm = 'DURING_INITIAL_LEASE_TERM',
  EndOfInitialLeaseTerm = 'END_OF_INITIAL_LEASE_TERM',
}

export type SubscriptionCostSummary = {
  __typename?: 'SubscriptionCostSummary';
  country: Country;
  /** Example: 'GBP' */
  currencyCode: Scalars['String']['output'];
  feeSummary: PricePlanFeeSummary;
  id: Scalars['ID']['output'];
  includesInsurance: Scalars['Boolean']['output'];
  initial: PricePlanInitialCostSummary;
  lastRecurringPeriod: Scalars['Int']['output'];
  merchantPrice?: Maybe<TaxableAmount>;
  recurring: PricePlanRecurringCostSummary;
  recurringPeriod: RecurringPeriod;
  termLength: Scalars['Int']['output'];
  variant?: Maybe<Variant>;
};

export type SubscriptionFeeSummary = {
  __typename?: 'SubscriptionFeeSummary';
  currentNonReturnFee?: Maybe<Money>;
};

export type SubscriptionPaymentInfo = Timestamps & {
  __typename?: 'SubscriptionPaymentInfo';
  bankAccountName?: Maybe<Scalars['String']['output']>;
  /** Example: 12345678 */
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  /** The type of bank account */
  bankAccountType?: Maybe<BankAccountType>;
  /** Example: 000000 */
  bankBranchIdentifier?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  directDebitMandateAuthorised: Scalars['Boolean']['output'];
  directDebitMandateAuthorisedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type SubscriptionPaymentScheduleEvent = {
  __typename?: 'SubscriptionPaymentScheduleEvent';
  amount: Money;
  payable: Scalars['Boolean']['output'];
  paymentOn: Scalars['ISO8601Date']['output'];
};

export enum SubscriptionState {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
}

export type SubscriptionTransaction = {
  __typename?: 'SubscriptionTransaction';
  amountExcludingTax: Money;
  amountIncludingTax: Money;
  description?: Maybe<Scalars['String']['output']>;
  happenedAt: Scalars['ISO8601DateTime']['output'];
};

export type SubscriptionUpgrade = {
  __typename?: 'SubscriptionUpgrade';
  canStartAssetReturn: Scalars['Boolean']['output'];
  checkout?: Maybe<Checkout>;
  dueOn?: Maybe<Scalars['ISO8601Date']['output']>;
  eligible: Scalars['Boolean']['output'];
  recommendedProducts: Array<Product>;
};

/** Arguments for swapping a checkout item */
export type SwapCheckoutItemInput = {
  /** An item ID that belongs to the checkout */
  existingItemId: Scalars['ID']['input'];
  newItem: CreateCheckoutItemInput;
};

/** Autogenerated return type of SwapCheckoutItem. */
export type SwapCheckoutItemPayload = {
  __typename?: 'SwapCheckoutItemPayload';
  checkout: Checkout;
  errors: Array<ValidationError>;
};

export type TaxableAmount = {
  __typename?: 'TaxableAmount';
  taxPercentage?: Maybe<Scalars['Float']['output']>;
  taxValue: Money;
  valueAfterTax: Money;
  valueBeforeTax: Money;
};

export type TimelineEvent = {
  __typename?: 'TimelineEvent';
  happenedAt: Scalars['ISO8601DateTime']['output'];
  /** Location where the event happened */
  location?: Maybe<Scalars['String']['output']>;
  /** Message to give more context to this event */
  message?: Maybe<Scalars['String']['output']>;
  /** Coarse state for this event; can have different state details */
  state: Scalars['String']['output'];
  /** Short description of the state */
  stateDescription?: Maybe<Scalars['String']['output']>;
  /** More discrete state */
  stateDetail?: Maybe<Scalars['String']['output']>;
};

export type Timestamps = {
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated return type of TrackCheckout. */
export type TrackCheckoutPayload = {
  __typename?: 'TrackCheckoutPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean']['output'];
};

export type TradeIn = Timestamps & {
  __typename?: 'TradeIn';
  costSummary?: Maybe<AdjustmentCostSummary>;
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  grade?: Maybe<TradeInGrade>;
  id: Scalars['ID']['output'];
  termsAndConditionsAcceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TradeInCostSummary = {
  __typename?: 'TradeInCostSummary';
  recurring?: Maybe<TradeInRecurringCostSummary>;
  total?: Maybe<Money>;
};

export type TradeInGrade = Timestamps & {
  __typename?: 'TradeInGrade';
  /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
  createdAt: Scalars['ISO8601DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TradeInGradingAnswer = Node & {
  __typename?: 'TradeInGradingAnswer';
  displayName: Scalars['String']['output'];
  followUpQuestion?: Maybe<TradeInGradingQuestion>;
  grade?: Maybe<TradeInGrade>;
  id: Scalars['ID']['output'];
};

export type TradeInGradingQuestion = Node & {
  __typename?: 'TradeInGradingQuestion';
  displayName: Scalars['String']['output'];
  gradingAnswers: Array<TradeInGradingAnswer>;
  hasLeadingGradingAnswers: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
};

/** Arguments for trade in max amount cost summary query field */
export type TradeInMaxCostSummaryInput = {
  countryId: Scalars['ID']['input'];
  gradeId?: InputMaybe<Scalars['ID']['input']>;
  originId: Scalars['ID']['input'];
};

export type TradeInRecurringCostSummary = {
  __typename?: 'TradeInRecurringCostSummary';
  amount?: Maybe<Money>;
  termLength?: Maybe<Scalars['Int']['output']>;
};

/** Arguments for updating a Checkout */
export type UpdateCheckoutInput = {
  address?: InputMaybe<CheckoutAddressInput>;
  customerInfo?: InputMaybe<CheckoutCustomerInfoInput>;
  deviceInfo?: InputMaybe<CheckoutDeviceInfoInput>;
  paymentInfo?: InputMaybe<CheckoutPaymentInfoInput>;
  previousAddress?: InputMaybe<CheckoutAddressInput>;
  recentChangeOfAddress?: InputMaybe<Scalars['Boolean']['input']>;
  recentChangeOfCountry?: InputMaybe<Scalars['Boolean']['input']>;
  termsAndConditionsAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  tradeInTermsAndConditionsAccepted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateCheckout. */
export type UpdateCheckoutPayload = {
  __typename?: 'UpdateCheckoutPayload';
  checkout?: Maybe<Checkout>;
  errors: Array<ValidationError>;
};

export type UpdateCheckoutTrackingInput = {
  campaignReference?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<CheckoutTrackingCategoryType>;
  clientSessionReference?: InputMaybe<Scalars['String']['input']>;
  contentReference?: InputMaybe<Scalars['String']['input']>;
  extractedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  interactionReference?: InputMaybe<Scalars['String']['input']>;
  landingPageUrl?: InputMaybe<Scalars['String']['input']>;
  mediumReference?: InputMaybe<Scalars['String']['input']>;
  partnerReference?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  referrerUrl?: InputMaybe<Scalars['String']['input']>;
  sourceReference?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  visitorReference?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateMerchantOrder. */
export type UpdateMerchantOrderPayload = {
  __typename?: 'UpdateMerchantOrderPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean']['output'];
};

export type ValidationError = {
  __typename?: 'ValidationError';
  /** Example: 'blank' */
  code?: Maybe<Scalars['String']['output']>;
  /** Example: 'customer_info.first_name' */
  field: Scalars['String']['output'];
  /** Example: 'can not be blank' */
  message?: Maybe<Scalars['String']['output']>;
};

export type Variant = Node &
  Timestamps & {
    __typename?: 'Variant';
    /** Variant is available for adding to checkouts */
    available: Scalars['Boolean']['output'];
    condition: VariantCondition;
    costSummaries?: Maybe<Array<VariantCostSummary>>;
    /** When this object was created. Example: 2011-12-13T14:15:16.171920Z */
    createdAt: Scalars['ISO8601DateTime']['output'];
    deliveryExpectedBetween?: Maybe<DateRange>;
    description?: Maybe<Scalars['String']['output']>;
    displayName?: Maybe<Scalars['String']['output']>;
    headlineTradeInMaxCostSummary?: Maybe<TradeInCostSummary>;
    /** Name used in the hire agreement */
    hireAgreementName?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    images?: Maybe<Array<Image>>;
    lowestCostSummary?: Maybe<VariantCostSummary>;
    optionValues?: Maybe<Array<OptionValue>>;
    position: Scalars['Int']['output'];
    /** Variant is available for checkout before its release */
    preOrder: Scalars['Boolean']['output'];
    product: Product;
    releasedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
    slug: Scalars['String']['output'];
    /** In markdown format */
    specifications?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tradeInMaxCostSummary?: Maybe<TradeInCostSummary>;
    /** When this object was last updated. Example: 2011-12-13T14:15:16.171920Z */
    updatedAt: Scalars['ISO8601DateTime']['output'];
  };

export type VariantAvailableArgs = {
  merchantId?: InputMaybe<Scalars['ID']['input']>;
};

export type VariantCostSummariesArgs = {
  merchantId: Scalars['ID']['input'];
};

export type VariantDeliveryExpectedBetweenArgs = {
  merchantId: Scalars['ID']['input'];
};

export type VariantHeadlineTradeInMaxCostSummaryArgs = {
  input: TradeInMaxCostSummaryInput;
};

export type VariantImagesArgs = {
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type VariantLowestCostSummaryArgs = {
  merchantId: Scalars['ID']['input'];
};

export type VariantTradeInMaxCostSummaryArgs = {
  input: TradeInMaxCostSummaryInput;
};

export enum VariantCondition {
  New = 'NEW',
  Refurbished = 'REFURBISHED',
}

/** The connection type for Variant. */
export type VariantConnection = {
  __typename?: 'VariantConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VariantEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Variant>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VariantCostSummary = {
  __typename?: 'VariantCostSummary';
  country: Country;
  /** Example: 'GBP' */
  currencyCode: Scalars['String']['output'];
  feeSummary: PricePlanFeeSummary;
  id: Scalars['ID']['output'];
  includesInsurance: Scalars['Boolean']['output'];
  initial: PricePlanInitialCostSummary;
  lastRecurringPeriod: Scalars['Int']['output'];
  merchantPrice?: Maybe<TaxableAmount>;
  recurring: PricePlanRecurringCostSummary;
  recurringPeriod: RecurringPeriod;
  termLength: Scalars['Int']['output'];
  variant?: Maybe<Variant>;
};

/** An edge in a connection. */
export type VariantEdge = {
  __typename?: 'VariantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Variant>;
};

/** Autogenerated return type of VerifyBankingConnection. */
export type VerifyBankingConnectionPayload = {
  __typename?: 'VerifyBankingConnectionPayload';
  success: Scalars['Boolean']['output'];
};

export type VariantCostSummaryInitialFieldsFragment = {
  __typename?: 'PricePlanInitialCostSummary';
  totalAmount: {
    __typename?: 'TaxableAmount';
    valueAfterTax: { __typename?: 'Money'; value: number };
    valueBeforeTax: { __typename?: 'Money'; value: number };
  };
};

export type VariantCostSummaryRecurringFieldsFragment = {
  __typename?: 'PricePlanRecurringCostSummary';
  totalAmount: {
    __typename?: 'TaxableAmount';
    valueAfterTax: { __typename?: 'Money'; value: number };
    valueBeforeTax: { __typename?: 'Money'; value: number };
  };
  insuranceAmount?: {
    __typename?: 'TaxableAmount';
    valueAfterTax: { __typename?: 'Money'; value: number };
    valueBeforeTax: { __typename?: 'Money'; value: number };
  } | null;
};

export type ProductsRepoCustomerSpecificPricingQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  merchantId: Scalars['ID']['input'];
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProductsRepoCustomerSpecificPricingQuery = {
  __typename?: 'Query';
  variants: Array<{
    __typename?: 'Variant';
    id: string;
    costSummaries?: Array<{
      __typename?: 'VariantCostSummary';
      id: string;
      recurring: {
        __typename?: 'PricePlanRecurringCostSummary';
        totalAmount: {
          __typename?: 'TaxableAmount';
          valueAfterTax: { __typename?: 'Money'; value: number };
          valueBeforeTax: { __typename?: 'Money'; value: number };
        };
        insuranceAmount?: {
          __typename?: 'TaxableAmount';
          valueAfterTax: { __typename?: 'Money'; value: number };
          valueBeforeTax: { __typename?: 'Money'; value: number };
        } | null;
      };
    }> | null;
  }>;
};

export type ProductsRepoCustomerSpecificLowestCostSummaryQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  merchantId: Scalars['ID']['input'];
  checkoutToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProductsRepoCustomerSpecificLowestCostSummaryQuery = {
  __typename?: 'Query';
  variants: Array<{
    __typename?: 'Variant';
    id: string;
    lowestCostSummary?: {
      __typename?: 'VariantCostSummary';
      id: string;
      recurring: {
        __typename?: 'PricePlanRecurringCostSummary';
        totalAmount: {
          __typename?: 'TaxableAmount';
          valueAfterTax: { __typename?: 'Money'; value: number };
          valueBeforeTax: { __typename?: 'Money'; value: number };
        };
        insuranceAmount?: {
          __typename?: 'TaxableAmount';
          valueAfterTax: { __typename?: 'Money'; value: number };
          valueBeforeTax: { __typename?: 'Money'; value: number };
        } | null;
      };
    } | null;
  }>;
};

export type ProductsRepoGetDeliveryDatesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  merchantId: Scalars['ID']['input'];
}>;

export type ProductsRepoGetDeliveryDatesQuery = {
  __typename?: 'Query';
  variants: Array<{
    __typename?: 'Variant';
    id: string;
    available: boolean;
    deliveryExpectedBetween?: { __typename?: 'DateRange'; max: string; min: string } | null;
  }>;
};

export type AccountGetPreApprovalDataWithTaxQueryQueryVariables = Exact<{ [key: string]: never }>;

export type AccountGetPreApprovalDataWithTaxQueryQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'Customer';
    id: string;
    preApproval: {
      __typename?: 'CustomerPreApproval';
      successful: boolean;
      recurringTaxableAmount?: {
        __typename?: 'TaxableAmount';
        valueAfterTax: { __typename?: 'Money'; formattedValue: string; value: number };
        valueBeforeTax: { __typename?: 'Money'; formattedValue: string; value: number };
      } | null;
    };
  } | null;
};

export type ProductsRepoGetProductVariantSlugsQueryVariables = Exact<{
  merchantId: Scalars['ID']['input'];
}>;

export type ProductsRepoGetProductVariantSlugsQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    id: string;
    variants: Array<{
      __typename?: 'Variant';
      id: string;
      slug: string;
      position: number;
      available: boolean;
    }>;
    lowestCostSummary?: {
      __typename?: 'VariantCostSummary';
      id: string;
      termLength: number;
    } | null;
  }>;
};

export type ProductsRepoGetPaginatedProductDetailsQueryVariables = Exact<{
  merchantId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProductsRepoGetPaginatedProductDetailsQuery = {
  __typename?: 'Query';
  productsConnection: {
    __typename?: 'ProductConnection';
    nodes?: Array<{
      __typename?: 'Product';
      available: boolean;
      id: string;
      displayName?: string | null;
      variantConditions: Array<VariantCondition>;
      position: number;
      manufacturer: { __typename?: 'Manufacturer'; id: string; displayName?: string | null };
      category: { __typename?: 'ProductCategory'; id: string; displayName?: string | null };
      variants: Array<{
        __typename?: 'Variant';
        id: string;
        displayName?: string | null;
        available: boolean;
        condition: VariantCondition;
        position: number;
        slug: string;
        images?: Array<{ __typename?: 'Image'; url: string }> | null;
        optionValues?: Array<{
          __typename?: 'OptionValue';
          raw?: string | null;
          displayName: string;
          optionType: { __typename?: 'OptionType'; slug: string };
        }> | null;
      }>;
    } | null> | null;
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean };
  };
};

export type ProductsRepoGetPaginatedProductLowestCostSummariesQueryVariables = Exact<{
  merchantId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProductsRepoGetPaginatedProductLowestCostSummariesQuery = {
  __typename?: 'Query';
  productsConnection: {
    __typename?: 'ProductConnection';
    nodes?: Array<{
      __typename?: 'Product';
      id: string;
      lowestCostSummary?: {
        __typename?: 'VariantCostSummary';
        id: string;
        termLength: number;
        recurring: {
          __typename?: 'PricePlanRecurringCostSummary';
          totalAmount: {
            __typename?: 'TaxableAmount';
            valueAfterTax: { __typename?: 'Money'; value: number };
            valueBeforeTax: { __typename?: 'Money'; value: number };
          };
          insuranceAmount?: {
            __typename?: 'TaxableAmount';
            valueAfterTax: { __typename?: 'Money'; value: number };
            valueBeforeTax: { __typename?: 'Money'; value: number };
          } | null;
        };
      } | null;
    } | null> | null;
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean };
  };
};

export type AccountIntercomQueryQueryVariables = Exact<{ [key: string]: never }>;

export type AccountIntercomQueryQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'Customer';
    id: string;
    email?: string | null;
    messagingDetails?: {
      __typename?: 'CustomerMessagingDetails';
      intercom?: {
        __typename?: 'CustomerIntercomDetails';
        appId: string;
        userHash: string;
        userId: string;
      } | null;
    } | null;
  } | null;
};

export type ProductsRepoProductCategoriesQueryVariables = Exact<{
  merchantId: Scalars['ID']['input'];
}>;

export type ProductsRepoProductCategoriesQuery = {
  __typename?: 'Query';
  productCategories: Array<{
    __typename?: 'ProductCategory';
    id: string;
    slug: string;
    displayName?: string | null;
  }>;
};

export type ProductsRepoVariantPricePlansQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  merchantId: Scalars['ID']['input'];
}>;

export type ProductsRepoVariantPricePlansQuery = {
  __typename?: 'Query';
  variants: Array<{
    __typename?: 'Variant';
    id: string;
    costSummaries?: Array<{
      __typename?: 'VariantCostSummary';
      id: string;
      includesInsurance: boolean;
      termLength: number;
      recurring: {
        __typename?: 'PricePlanRecurringCostSummary';
        totalAmount: {
          __typename?: 'TaxableAmount';
          valueAfterTax: { __typename?: 'Money'; value: number };
          valueBeforeTax: { __typename?: 'Money'; value: number };
        };
        insuranceAmount?: {
          __typename?: 'TaxableAmount';
          valueAfterTax: { __typename?: 'Money'; value: number };
          valueBeforeTax: { __typename?: 'Money'; value: number };
        } | null;
      };
      initial: {
        __typename?: 'PricePlanInitialCostSummary';
        totalAmount: {
          __typename?: 'TaxableAmount';
          valueAfterTax: { __typename?: 'Money'; value: number };
          valueBeforeTax: { __typename?: 'Money'; value: number };
        };
      };
      feeSummary: {
        __typename?: 'PricePlanFeeSummary';
        nonReturnFee?: { __typename?: 'Money'; formattedValue: string } | null;
      };
    }> | null;
  }>;
};

export type ProductsRepoProductVariantConditionsQueryVariables = Exact<{
  productCategoryIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  merchantId: Scalars['ID']['input'];
}>;

export type ProductsRepoProductVariantConditionsQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    id: string;
    variants: Array<{ __typename?: 'Variant'; id: string; condition: VariantCondition }>;
  }>;
};

export type ProductsRepoProductVariantsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  merchantId: Scalars['ID']['input'];
}>;

export type ProductsRepoProductVariantsQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    id: string;
    canAddTradeIn: boolean;
    displayName?: string | null;
    specifications?: string | null;
    keyFeatures?: string | null;
    overview?: string | null;
    optionTypes: Array<{
      __typename?: 'OptionType';
      id: string;
      slug: string;
      displayName?: string | null;
      position: number;
    }>;
    category: { __typename?: 'ProductCategory'; id: string; displayName?: string | null };
    manufacturer: { __typename?: 'Manufacturer'; id: string; displayName?: string | null };
    variants: Array<{
      __typename?: 'Variant';
      id: string;
      displayName?: string | null;
      slug: string;
      subtitle?: string | null;
      specifications?: string | null;
      position: number;
      available: boolean;
      condition: VariantCondition;
      images?: Array<{
        __typename?: 'Image';
        alt?: string | null;
        position: number;
        tag?: string | null;
        title?: string | null;
        url: string;
      }> | null;
      optionValues?: Array<{
        __typename?: 'OptionValue';
        id: string;
        displayName: string;
        raw?: string | null;
        position: number;
        optionType: { __typename?: 'OptionType'; id: string; slug: string };
      }> | null;
    }>;
  }>;
};

export const VariantCostSummaryInitialFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VariantCostSummaryInitialFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PricePlanInitialCostSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAfterTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueBeforeTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VariantCostSummaryInitialFieldsFragment, unknown>;
export const VariantCostSummaryRecurringFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VariantCostSummaryRecurringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PricePlanRecurringCostSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAfterTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueBeforeTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insuranceAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAfterTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueBeforeTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VariantCostSummaryRecurringFieldsFragment, unknown>;
export const ProductsRepoCustomerSpecificPricingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductsRepoCustomerSpecificPricing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkoutToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forCheckout' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'checkoutToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'checkoutToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'costSummaries' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'merchantId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'VariantCostSummaryRecurringFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VariantCostSummaryRecurringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PricePlanRecurringCostSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAfterTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueBeforeTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insuranceAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAfterTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueBeforeTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductsRepoCustomerSpecificPricingQuery,
  ProductsRepoCustomerSpecificPricingQueryVariables
>;
export const ProductsRepoCustomerSpecificLowestCostSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductsRepoCustomerSpecificLowestCostSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkoutToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forCheckout' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'checkoutToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'checkoutToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lowestCostSummary' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'merchantId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'VariantCostSummaryRecurringFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VariantCostSummaryRecurringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PricePlanRecurringCostSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAfterTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueBeforeTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insuranceAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAfterTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueBeforeTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductsRepoCustomerSpecificLowestCostSummaryQuery,
  ProductsRepoCustomerSpecificLowestCostSummaryQueryVariables
>;
export const ProductsRepoGetDeliveryDatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductsRepoGetDeliveryDates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forCheckout' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'available' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'merchantId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryExpectedBetween' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'merchantId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'min' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductsRepoGetDeliveryDatesQuery,
  ProductsRepoGetDeliveryDatesQueryVariables
>;
export const AccountGetPreApprovalDataWithTaxQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountGetPreApprovalDataWithTaxQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'preApproval' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurringTaxableAmount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'valueAfterTax' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'formattedValue' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'valueBeforeTax' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'formattedValue' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'successful' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountGetPreApprovalDataWithTaxQueryQuery,
  AccountGetPreApprovalDataWithTaxQueryQueryVariables
>;
export const ProductsRepoGetProductVariantSlugsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductsRepoGetProductVariantSlugs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forCheckoutInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyAvailable' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'forCheckoutInput' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'onlyAvailable' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'merchantId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'available' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'merchantId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'merchantId' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lowestCostSummary' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'merchantId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'termLength' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductsRepoGetProductVariantSlugsQuery,
  ProductsRepoGetProductVariantSlugsQueryVariables
>;
export const ProductsRepoGetPaginatedProductDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductsRepoGetPaginatedProductDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productsConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forCheckoutInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyAvailable' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'available' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variantConditions' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manufacturer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'forCheckoutInput' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'onlyAvailable' },
                                  value: { kind: 'BooleanValue', value: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'merchantId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'merchantId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'available' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'merchantId' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'merchantId' },
                                  },
                                },
                              ],
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionValues' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'optionType' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductsRepoGetPaginatedProductDetailsQuery,
  ProductsRepoGetPaginatedProductDetailsQueryVariables
>;
export const ProductsRepoGetPaginatedProductLowestCostSummariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductsRepoGetPaginatedProductLowestCostSummaries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productsConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forCheckoutInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyAvailable' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lowestCostSummary' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'merchantId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'merchantId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'termLength' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'recurring' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'VariantCostSummaryRecurringFields',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VariantCostSummaryRecurringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PricePlanRecurringCostSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAfterTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueBeforeTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insuranceAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAfterTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueBeforeTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductsRepoGetPaginatedProductLowestCostSummariesQuery,
  ProductsRepoGetPaginatedProductLowestCostSummariesQueryVariables
>;
export const AccountIntercomQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountIntercomQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messagingDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'intercom' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'appId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userHash' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountIntercomQueryQuery, AccountIntercomQueryQueryVariables>;
export const ProductsRepoProductCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductsRepoProductCategories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductsRepoProductCategoriesQuery,
  ProductsRepoProductCategoriesQueryVariables
>;
export const ProductsRepoVariantPricePlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductsRepoVariantPricePlans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forCheckout' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'costSummaries' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'merchantId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includesInsurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'termLength' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'VariantCostSummaryRecurringFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'initial' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'VariantCostSummaryInitialFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'feeSummary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nonReturnFee' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'formattedValue' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VariantCostSummaryRecurringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PricePlanRecurringCostSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAfterTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueBeforeTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insuranceAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAfterTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueBeforeTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VariantCostSummaryInitialFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PricePlanInitialCostSummary' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalAmount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueAfterTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'valueBeforeTax' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductsRepoVariantPricePlansQuery,
  ProductsRepoVariantPricePlansQueryVariables
>;
export const ProductsRepoProductVariantConditionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductsRepoProductVariantConditions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productCategoryIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forCheckout' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productCategoryIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productCategoryIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'forCheckout' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'merchantId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductsRepoProductVariantConditionsQuery,
  ProductsRepoProductVariantConditionsQueryVariables
>;
export const ProductsRepoProductVariantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductsRepoProductVariants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forCheckoutInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'onlyAvailable' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'merchantId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canAddTradeIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'specifications' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keyFeatures' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optionTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'manufacturer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'overview' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variants' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'forCheckoutInput' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'onlyAvailable' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'merchantId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'merchantId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'specifications' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'available' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'merchantId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'merchantId' },
                            },
                          },
                        ],
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optionValues' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'raw' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProductsRepoProductVariantsQuery,
  ProductsRepoProductVariantsQueryVariables
>;
