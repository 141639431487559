import constate from 'constate';
import { useRayloCookiesContext } from './useRayloCookiesContext';
import { GET_PREAPPROVAL_DATA } from '@/graphql/operations/getPreApprovalData';
import { useQuery } from '@apollo/client';
import { useConsumerTypeContext } from './useConsumerTypeContext';
import { useAuthTokenContext } from './useAuthTokenContext';
import { userIsLoggedIn } from '@/utils/auth/userAuth';
import { useMemo } from 'react';

const useCustomer = () => {
  const { customerIsInMobileApp, authToken } = useAuthTokenContext();
  const { consumerMoneyField } = useConsumerTypeContext();
  const { cookieValues } = useRayloCookiesContext();
  const {
    raylo_checkoutContext: checkoutContext,
    raylo_checkoutToken: checkoutToken,
    raylo_subscriptionId: subscriptionId,
  } = cookieValues;

  const hasAuthenticatedUser = useMemo(() => userIsLoggedIn(authToken), [authToken]);

  const { data: customerData, loading: isCustomerDataLoading } = useQuery(GET_PREAPPROVAL_DATA, {
    skip: !hasAuthenticatedUser,
  });

  const preApproval = customerData?.customer?.preApproval;

  const inAddTechContext =
    hasAuthenticatedUser && (checkoutContext === 'ADDITIONAL_TECH' || !checkoutContext);

  const eligibleForAddTech = inAddTechContext && !!preApproval?.successful;

  const eligibleForUpgrade =
    hasAuthenticatedUser && checkoutContext === 'UPGRADE' && !!subscriptionId;

  const hasLoggedInCustomer = eligibleForAddTech || eligibleForUpgrade;

  return {
    isCustomerDataReady: !hasAuthenticatedUser || isCustomerDataLoading === false,
    hasLoggedInCustomer,
    customerIsInMobileApp,
    loggedInAndInMobileApp: hasLoggedInCustomer && customerIsInMobileApp,
    eligibleForAddTech,
    eligibleForUpgrade,
    subscriptionId,
    checkoutContext,
    checkoutToken,
    formattedPreApprovedAmount:
      preApproval?.recurringTaxableAmount?.[consumerMoneyField]?.formattedValue,
    preApprovedAmount: preApproval?.recurringTaxableAmount?.[consumerMoneyField]?.value,
  };
};

const [CustomerProvider, useCustomerContext] = constate(useCustomer);
export { CustomerProvider, useCustomerContext };
