import { useEffect, useState, useRef } from 'react';
import constate from 'constate';
import { rayloLight } from '../styles/themes/rayloLight';
import { getThemeFromString } from '../utils/getThemeFromString';
import { useRouter } from 'next/router';
import { DefaultTheme } from 'styled-components';
import { consumerTypeKeysList, consumerTypePathBase } from '@/types/consumerTypes';
import { prefixUrlPathByConsumerType } from '@/utils/urls.utils';

type Modals =
  | 'afterLeaseChoices'
  | 'lifetimeWarranty'
  | 'rayloTradeIn'
  | 'softCreditCheck'
  | 'filters'
  | 'aboutYourLimit'
  | 'notApprovedDevice'
  | 'someEligible'
  | 'allIneligible'
  | null;

type AppMerchantState = {
  isRayloPay: boolean;
  merchantDomain: string;
  initialUrlParams: string;
};

const useApp = () => {
  const scrollPosition = useRef<number>(0);
  const router = useRouter();

  const [theme, setTheme] = useState<DefaultTheme>(rayloLight);
  const [conditionTabSelection, setConditionTabSelection] = useState<number | undefined>(0);

  const [modalOpen, setModalOpen] = useState<Modals>(null);
  const [previousPageIsProducts, setPreviousPageIsProducts] = useState<boolean>(false);
  const [isShowingPreApprovedBanner, setIsShowingPreApprovedBanner] = useState(false);

  const [rayloPlatformState, setRayloPlatformState] = useState<AppMerchantState>({
    isRayloPay: false,
    merchantDomain: '',
    initialUrlParams: '',
  });

  /**
   * When the client loads the app, update the state if needed. This is a core part of the app, and
   * tells the F/E if the app is in `Raylo Pay` mode, or in the normal mode. It'll also update the
   * theme if needed. As the majority of the app is pre-rendered at build time, we need to update
   * the state on the client side, otherwise it can cause a hydration issue because the `RayloPay`
   * markup can be different compared to the default markup. In future, if we move to the Next.js
   * App Router, we should be able to calculate the state on the server side, which would allow us
   * to migrate this logic there.
   */
  useEffect(() => {
    const localEnvTestDomain = new URLSearchParams(window?.location.search).get('domain');

    const merchantDomain = localEnvTestDomain || window.location.hostname;
    const isRayloPay = merchantDomain.includes('raylopay');

    setRayloPlatformState({
      isRayloPay,
      merchantDomain,
      initialUrlParams: window.location.search,
    });

    if (merchantDomain && isRayloPay) {
      const themeToUse = getThemeFromString(merchantDomain);

      if (themeToUse) {
        setTheme(themeToUse);
      }
    }
  }, []);

  useEffect(() => {
    document.documentElement.style.overflow = modalOpen ? 'hidden' : 'auto';
  }, [modalOpen]);

  useEffect(() => {
    const routeChangeStart = () => {
      /**
       * Create an array which looks like:
       *
       * ```
       * ['/products', '/business/products'];
       * ```
       */
      const allProductsPathnames = consumerTypeKeysList.map((consumerType) =>
        prefixUrlPathByConsumerType(consumerType, '/products'),
      );

      // Check if the current pathname is in the array
      const pathnameIsProducts = allProductsPathnames.includes(router.pathname);
      if (pathnameIsProducts) {
        scrollPosition.current = window.scrollY;
      }
      setPreviousPageIsProducts(pathnameIsProducts);
    };

    const routeChangeFinish = (url: string) => {
      if (url.includes('/products') && !url.includes('/products/')) {
        window.scroll({
          top: scrollPosition.current,
          behavior: 'auto',
        });
      }
    };

    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeComplete', routeChangeFinish);

    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeComplete', routeChangeFinish);
    };
  }, [router.pathname, router.events]);

  return {
    theme,
    isRayloPay: rayloPlatformState.isRayloPay,
    conditionTabSelection,
    modalOpen,
    initialUrlParams: rayloPlatformState.initialUrlParams,
    merchantDomain: rayloPlatformState.merchantDomain,
    previousPageIsProducts,
    setTheme,
    setConditionTabSelection,
    setModalOpen,
    isShowingPreApprovedBanner,
    setIsShowingPreApprovedBanner,
  };
};

const [AppProvider, useAppContext] = constate(useApp);
export { AppProvider, useAppContext };
