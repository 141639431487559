import { ProductCategories } from '@/types/productTypes';
import { PropsWithChildren } from 'react';
import { ConsumerTypesEnum, GlobalUIStyles, NavDataModel } from 'uibook';
import { CustomGrowthBookProvider } from './CustomGrowthBookProvider';
import { CookiesProvider } from 'react-cookie';
import { RayloCookiesProvider } from '@/hooks/useRayloCookiesContext';
import { RayloIFrameProvider } from '@/hooks/useRayloIFrameContext';
import { AuthTokenContextProvider } from '@/hooks/useAuthTokenContext';
import { IntercomProvider } from 'react-use-intercom';
import { CustomApolloProvider } from './CustomApolloProvider';
import { ConsumerTypeProvider } from '@/hooks/useConsumerTypeContext';
import { CustomerProvider } from '@/hooks/useCustomerContext';
import { AppProvider } from '@/hooks/useAppContext';
import { ContentProvider } from '@/hooks/useContentContext';
import { CategoriesProvider } from '@/hooks/useCategoriesContext';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import GlobalNavBar from '@/components/GlobalNavBar/GlobalNavBar';
import AppLayout from '@/components/Layouts/App';

export type RayloAppProps = {
  /**
   * `categories` is fetched in `getStaticProps` in the PLP and PDP pages, and is then and passed to
   * the `_app.tsx` component via the `pageProps`. Same for `navData`.
   *
   * `consumerType` is currently only set PDP and PLP pages. Any other pages, it will default to
   * `ConsumerTypesEnum.PERSONAL`.
   */
  categories?: ProductCategories;
  navData?: NavDataModel;
  consumerType?: ConsumerTypesEnum;
};

export const GlobalAppProvider = ({
  categories,
  navData,
  consumerType,
  children,
}: PropsWithChildren<RayloAppProps>) => {
  return (
    <CustomGrowthBookProvider>
      <CookiesProvider>
        <RayloCookiesProvider>
          <RayloIFrameProvider>
            <AuthTokenContextProvider>
              <IntercomProvider
                appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID}
                initializeDelay={2000}
              >
                <CustomApolloProvider>
                  <ConsumerTypeProvider consumerType={consumerType}>
                    <CustomerProvider>
                      <AppProvider>
                        <ContentProvider>
                          <CategoriesProvider categories={categories ?? { productCategories: [] }}>
                            <StyleSheetManager shouldForwardProp={isPropValid}>
                              <GlobalUIStyles />
                              <GlobalNavBar navData={navData ?? { categories: [] }} />
                              <AppLayout>{children}</AppLayout>
                            </StyleSheetManager>
                          </CategoriesProvider>
                        </ContentProvider>
                      </AppProvider>
                    </CustomerProvider>
                  </ConsumerTypeProvider>
                </CustomApolloProvider>
              </IntercomProvider>
            </AuthTokenContextProvider>
          </RayloIFrameProvider>
        </RayloCookiesProvider>
      </CookiesProvider>
    </CustomGrowthBookProvider>
  );
};
