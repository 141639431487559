import { StyledBurgerNavButton, StyledLoginIcon } from '../NavBarWrapper/NavBarWrapper.styles';
import {
  StyledCloseButton,
  StyledMenuItem,
  StyledMenuListItem,
  StyledNavigateBack,
} from '../SideNavSubMenu/SideNavSubMenu.styles';
import { Link, MenuItemProps, NavigateBackProps } from '../Navbar.types';
import PhoneIcon from '../svgs/phones-nav';
import LaptopsIcon from '../svgs/laptops-nav';
import DesktopsIcon from '../svgs/desktops-nav';
import ConsolesIcon from '../svgs/consoles-nav';
import TabletsIcon from '../svgs/tablets-nav';
import HeadphonesIcon from '../svgs/headphones-nav';
import WatchesIcon from '../svgs/watches-nav';
import TVsIcon from '../svgs/tvs-nav';
import { palette } from '../../../colors';
import SpeakersNav from '../svgs/speakers-nav';

export const BurgerNav = ({
  onClick,
  sideNavOpen,
  dataTestId,
}: {
  onClick?: () => void;
  sideNavOpen: boolean;
  dataTestId?: string;
}) => (
  <StyledBurgerNavButton
    className="burger-nav-button"
    onClick={onClick}
    $sideNavOpen={sideNavOpen}
    data-testid={dataTestId}
  >
    <span data-testid={`${dataTestId}-1`} />
    <span data-testid={`${dataTestId}-2`} />
    <span data-testid={`${dataTestId}-3`} />
  </StyledBurgerNavButton>
);

export const MenuItem = ({
  label,
  onClick,
  icon,
  isBold,
  hideChevron,
  border,
  iconSize,
  dataTestId,
  staticIcon,
  productsUrl,
  LinkComponent,
  as,
}: MenuItemProps) => (
  <StyledMenuListItem as={as} className="menu-item">
    <StyledMenuItem
      data-testid={dataTestId}
      title={label ?? 'raylo product category'}
      as={(LinkComponent ?? typeof onClick === 'string') ? 'a' : 'button'}
      onClick={typeof onClick === 'string' ? undefined : onClick}
      href={typeof onClick === 'string' ? `${productsUrl ?? ''}${onClick}` : undefined}
      $icon={icon}
      $isBold={isBold}
      $hideChevron={hideChevron}
      $border={border}
      $iconSize={iconSize}
      $staticIcon={staticIcon}
    >
      {icon && getIcon(icon)}
      {label}
    </StyledMenuItem>
  </StyledMenuListItem>
);

export const NavigateBack = ({ onClick, title, titleLink }: NavigateBackProps) => (
  <StyledNavigateBack data-testid="back">
    <button data-testid="back-button" onClick={onClick} />
    {titleLink ? <NavLink href={titleLink} title={title} /> : <p>{title}</p>}
  </StyledNavigateBack>
);

type MenuLinksProps = {
  links: Link[];
  dataTestId?: string;
  LinkComponent?: React.ElementType;
};

export const MenuLinks = ({ dataTestId, links, LinkComponent }: MenuLinksProps) => (
  <>
    {links.map(({ displayName, link }, i) => (
      <StyledMenuItem
        data-testid={`${dataTestId}-${i}`}
        key={displayName}
        as={LinkComponent ?? 'a'}
        href={link}
        $hideChevron
      >
        {displayName}
      </StyledMenuItem>
    ))}
  </>
);

export const CloseButton = ({
  onClick,
  dataTestId,
  fillColour,
  hoverFillColour,
}: {
  onClick: () => void;
  dataTestId?: string;
  fillColour?: string;
  hoverFillColour?: string;
}) => {
  const resolvedFillColour = fillColour ?? palette.white;
  const resolvedHoverFillColour = hoverFillColour ?? palette.blue[200];
  return (
    <StyledCloseButton
      data-testid={dataTestId}
      onClick={onClick}
      $fillColour={resolvedFillColour}
      $hoverFillColour={resolvedHoverFillColour}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          data-testid={`${dataTestId}-rect`}
          x="12.707"
          y="12.707"
          width="12"
          height="2"
          transform="rotate(135 12.707 12.707)"
          fill={resolvedFillColour}
        />
        <rect
          x="21.1934"
          y="4.22192"
          width="12"
          height="2"
          transform="rotate(135 21.1934 4.22192)"
          fill={resolvedFillColour}
        />
        <rect
          x="11.293"
          y="12.707"
          width="12"
          height="2"
          transform="rotate(-135 11.293 12.707)"
          fill={resolvedFillColour}
        />
        <rect
          x="19.7773"
          y="21.1924"
          width="12"
          height="2"
          transform="rotate(-135 19.7773 21.1924)"
          fill={resolvedFillColour}
        />
      </svg>
    </StyledCloseButton>
  );
};

export const LoginIcon = ({ accountHomeUrl }: { accountHomeUrl: string }) => (
  <StyledLoginIcon
    data-testid="login"
    href={`${accountHomeUrl}/account`}
    className="login"
    title="Login"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0ZM18.13 17.9C18.0643 18.0079 17.9716 18.0968 17.8612 18.1582C17.7508 18.2195 17.6263 18.2512 17.5 18.25H6.5C6.37369 18.2512 6.24923 18.2195 6.1388 18.1582C6.02837 18.0968 5.93576 18.0079 5.87 17.9C5.80871 17.7899 5.77653 17.666 5.77653 17.54C5.77653 17.414 5.80871 17.2901 5.87 17.18C6.42474 16.0105 7.29992 15.0224 8.39387 14.3306C9.48781 13.6387 10.7556 13.2714 12.05 13.2714C13.3444 13.2714 14.6122 13.6387 15.7061 14.3306C16.8001 15.0224 17.6753 16.0105 18.23 17.18C18.2767 17.2985 18.292 17.427 18.2745 17.5531C18.257 17.6793 18.2072 17.7987 18.13 17.9ZM12 4.35C12.7318 4.35 13.4472 4.567 14.0556 4.97356C14.6641 5.38012 15.1383 5.95798 15.4184 6.63407C15.6984 7.31016 15.7717 8.0541 15.6289 8.77183C15.4861 9.48956 15.1338 10.1488 14.6163 10.6663C14.0988 11.1837 13.4396 11.5361 12.7218 11.6789C12.0041 11.8217 11.2602 11.7484 10.5841 11.4684C9.90799 11.1883 9.33013 10.7141 8.92357 10.1056C8.51701 9.49715 8.3 8.78179 8.3 8.05C8.3 7.0687 8.68983 6.12759 9.38371 5.4337C10.0776 4.73982 11.0187 4.35 12 4.35Z"
        fill="white"
      />
    </svg>
  </StyledLoginIcon>
);

const getIcon = (icon: string) => {
  switch (icon) {
    case 'phones':
      return <PhoneIcon />;
    case 'laptops':
      return <LaptopsIcon />;
    case 'desktops':
      return <DesktopsIcon />;
    case 'consoles':
      return <ConsolesIcon />;
    case 'tablets':
      return <TabletsIcon />;
    case 'headphones':
      return <HeadphonesIcon />;
    case 'watches':
      return <WatchesIcon />;
    case 'tvs':
      return <TVsIcon />;
    case 'speakers':
      return <SpeakersNav />;
    default:
      return undefined;
  }
};

export const NavLink = ({
  href,
  title,
  dataTestId,
  LinkComponent,
  className,
}: {
  href: string;
  title: string;
  dataTestId?: string;
  LinkComponent?: React.ElementType;
  className?: string;
}) => (
  <>
    {LinkComponent ? (
      <LinkComponent className={className} data-testid={dataTestId} href={href} title={title}>
        {title}
      </LinkComponent>
    ) : (
      <a className={className} data-testid={dataTestId} href={href} title={title}>
        {title}
      </a>
    )}
  </>
);
