import { px2Rem } from '../../../utils/px2Rem';
import { Dispatch, SetStateAction } from 'react';
import Button from '../../../designSystem/components/Button';
import TextAccordion from '../../TextAccordion';
import { MenuLinks, MenuItem } from '../utils/utilComponents';
import { palette } from '../../../colors';
import { helpSubMenuLinks } from '../utils/utils';
import SectionMessageWithIcon from '../../../designSystem/components/SectionMessageWithIcon';
import { StyledQuickLinks } from './NavBarQuickLinks.styles';
import { useNavBarContext } from '../NavBarContext/NavBarContext';

export const NavbarQuickLinks = ({
  accountHomeUrl,
  setSideNavOpen,
}: {
  accountHomeUrl: string;
  setSideNavOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { aboutSubMenuLinks, LinkComponent, consumerIsBusiness, suffixUrlsWithConsumerType } =
    useNavBarContext();
  const howItWorksOnClick = () => {
    setSideNavOpen(false);
    window.location.assign(
      `https://www.raylo.com/${consumerIsBusiness ? 'business' : ''}#how-it-works`,
    );
  };

  return (
    <StyledQuickLinks>
      <MenuItem
        onClick={howItWorksOnClick}
        label="How it works"
        staticIcon="nav-recycle"
        iconSize={24}
        isBold
        border
        as="div"
      />
      <TextAccordion
        dataTestId="about-accordion"
        primaryColour={palette.charcoal[500]}
        data={[
          {
            title: 'About',
            body: (
              <MenuLinks
                links={aboutSubMenuLinks.map((link) => ({
                  ...link,
                  link: suffixUrlsWithConsumerType(link.link),
                }))}
                LinkComponent={LinkComponent}
              />
            ),
          },
        ]}
        boldHeaders
        showDivider
        iconSize={16}
        headerBackgroundHoverColor={palette.charcoal[100]}
        isSubMenu
        iconWidth={3}
        iconPaddingLeft={0}
      />
      <TextAccordion
        dataTestId="help-accordion"
        primaryColour={palette.charcoal[500]}
        data={[
          {
            title: 'Help',
            body: (
              <MenuLinks
                links={helpSubMenuLinks.map((link) => ({
                  ...link,
                  link: suffixUrlsWithConsumerType(link.link),
                }))}
              />
            ),
          },
        ]}
        boldHeaders
        showDivider
        iconSize={16}
        headerBackgroundHoverColor={palette.charcoal[100]}
        isSubMenu
        iconWidth={3}
        iconPaddingLeft={0}
      />
      <div style={{ padding: px2Rem(24) }}>
        <div style={{ marginBottom: px2Rem(24) }}>
          <Button onClick={() => window.location.assign(`${accountHomeUrl}/account`)}>Login</Button>
        </div>
        <SectionMessageWithIcon
          backgroundColor={palette.blue[100]}
          borderColor={palette.blue[200]}
          copy="Raylo is proud to be B Corporation Certified"
          icon="RayloDesignCertified"
          iconLeftAlign
        />
      </div>
    </StyledQuickLinks>
  );
};
