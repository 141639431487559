import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import { GoogleTagManager } from '@next/third-parties/google';
import { DemoModeBannerWrapper } from '@/components/DemoModeBannerWrapper/DemoModeBannerWrapper';
import { GlobalAppProvider, RayloAppProps } from '@/providers/GlobalAppProvider';

function App(props: AppProps<RayloAppProps>) {
  return (
    <GlobalAppProvider {...props.pageProps}>
      <DemoModeBannerWrapper />
      <props.Component {...props.pageProps} />
      <GoogleTagManager gtmId="GTM-TB6BVN3" />
    </GlobalAppProvider>
  );
}

export default App;
