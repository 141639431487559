import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { ICopy } from './types';
import { CSS_FONT_WEIGHT } from 'uibook';

const StyledCopy = styled.p<ICopy>`
  margin: 0;
  ${({ color }) => color && `color: ${color};`}
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
  ${({ bold }) => bold && `font-weight: ${CSS_FONT_WEIGHT.vars.bold};`}
  ${({ medium }) => medium && `font-weight: ${CSS_FONT_WEIGHT.vars.medium};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ italic }) => italic && 'font-style: italic;'}
  ${({ lineHeight }) => lineHeight && `line-height: ${px2Rem(lineHeight)};`}
  ${({ center }) => center && 'text-align: center'}
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${px2Rem(letterSpacing)};`}
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${({ textWrap }) => textWrap && `text-wrap: ${textWrap};`}
`;

export { StyledCopy };
