import { ReactNode, useEffect, useState } from 'react';
import constate from 'constate';
import Copy from '../elements/Copy';
import Spacer from '../elements/Spacer';
import { useAppContext } from './useAppContext';
import Container from '../elements/Container';
import IconWithText from '../components/iconwithtext';
import { useRouter } from 'next/router';
import TextLink from '../elements/TextLink';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { SHOW_ABOUT_US_LINK, SHOW_ONLY_PHONES } from '@/constants/features';
import { IconName } from '@/elements/Icon/types';
import { ConsumerTypesEnum, palette } from 'uibook';
import { useConsumerTypeContext } from './useConsumerTypeContext';
import { consumerTypePathBase } from '@/types/consumerTypes';

export interface ILinks {
  title?: string;
  links?: ILink[];
}

export interface ILink {
  label: string;
  link: string;
}

interface ISocialMediaLinks {
  icon: IconName;
  link: string;
}

interface IAboutMenuLinks {
  displayName: string;
  link: string;
}

interface IPdpValidationLogos {
  title: string;
  url: string;
}

export interface ICertifiedInfoItems {
  linkHeader: string;
  cardIcon: IconName;
  cardTitle: string;
  cardCopy: ReactNode;
}

export interface IBenefitsListItems {
  icon: IconName;
  title: string;
  copy: string;
}

const useContent = () => {
  const { consumerIsBusiness } = useConsumerTypeContext();

  const [listOne, setListOne] = useState<ILinks>({});
  const [listTwo, setListTwo] = useState<ILinks>({});
  const [listThree, setListThree] = useState<ILinks>({});
  const [footerListOne, setFooterListOne] = useState<ILinks>({});
  const [footerListTwo, setFooterListTwo] = useState<ILinks>({});
  const [footerSocialMediaLinks, setFooterSocialMediaLinks] = useState<ISocialMediaLinks[] | []>(
    [],
  );
  const [aboutMenuLinks, setAboutMenuLinks] = useState<IAboutMenuLinks[] | []>([]);
  const [pdpValidationLogos, setPdpValidationLogos] = useState<IPdpValidationLogos[] | undefined>();
  const [certifiedInfoItems, setCertifiedInfoItems] = useState<ICertifiedInfoItems[] | undefined>();
  const [benefitsListItems, setBenefitsListItems] = useState<IBenefitsListItems[] | undefined>();

  const [trustPilotFooterLogo, setTrustPilotFooterLogo] = useState<string | undefined>();

  const { isRayloPay } = useAppContext();
  const showAboutUsLink: boolean = useFeatureIsOn(SHOW_ABOUT_US_LINK);
  const showOnlyPhones: boolean = useFeatureIsOn(SHOW_ONLY_PHONES);

  useEffect(() => {
    setListOne({
      title: 'Refurbished bestsellers',
      links: [
        {
          label: 'Refurbished iPhone XR 64GB Black',
          link: 'https://www.raylo.com/products/refurbished-iphone-xr-64gb-black',
        },
        {
          label: 'Refurbished iPhone XR 64GB White',
          link: 'https://www.raylo.com/products/refurbished-iphone-xr-64gb-white',
        },
        {
          label: 'Refurbished iPhone XS Max 64GB Gold',
          link: 'https://www.raylo.com/products/refurbished-iphone-xs-max-64gb-gold',
        },
        {
          label: 'Refurbished iPhone XS 64GB Gold',
          link: 'https://www.raylo.com/products/refurbished-iphone-xs-64gb-gold',
        },
      ],
    });
    setListTwo({
      title: 'iPhone 13 bestsellers',
      links: [
        {
          label: 'iPhone 13 128GB Blue',
          link: 'https://www.raylo.com/products/new-apple-iphone-13-128gb-blue-24-months',
        },
        {
          label: 'iPhone 13 128GB Midnight',
          link: 'https://www.raylo.com/products/new-apple-iphone-13-128gb-midnight-24-months',
        },
        {
          label: 'iPhone 13 Pro 128GB Sierra Blue',
          link: 'https://www.raylo.com/products/iphone-13-pro-128gb-sierra-blue-24-months',
        },
        {
          label: 'iPhone 13 Pro 128GB Graphite',
          link: 'https://www.raylo.com/products/iphone-13-pro-128gb-graphite-24-months',
        },
      ],
    });
    setListThree({
      title: 'Raylo Guides',
      links: [
        {
          label: 'Refurbished iPhones',
          link: 'https://www.raylo.com/post/refurbished-iphones',
        },
        {
          label: 'iPhone deals',
          link: 'https://www.raylo.com/post/iphone-deals',
        },
        {
          label: 'SIM-free phones',
          link: 'https://www.raylo.com/post/sim-free-phones',
        },
        {
          label: 'iPhone Upgrade Programme alternative',
          link: 'https://www.raylo.com/post/iphone-upgrade-programme',
        },
      ],
    });
    setFooterListOne({
      title: 'Raylo',
      links: [
        {
          label: 'My Account',
          link: 'https://app.raylo.com/login?_ga=2.244162309.1234187286.1648466711-1412900303.1643731306&_gac=1.128649982.1647352336.CjwKCAjw8sCRBhA6EiwA6_IF4T5ULLG4cUeaWZgjpyMtdEqx50wWKdJgbZkr3e2dviPbJmIEYPMG0RoCXXsQAvD_BwE',
        },
        {
          label: 'Sustainability',
          link: 'https://www.raylo.com/sustainability',
        },
        {
          label: 'How it works',
          link: `https://www.raylo.com${consumerIsBusiness ? consumerTypePathBase[ConsumerTypesEnum.BUSINESS] : '/'}#how-it-works`,
        },
        {
          label: 'Pricing',
          link: 'https://www.raylo.com/#pricing',
        },
        {
          label: 'Blog',
          link: 'https://www.raylo.com/blog',
        },
        {
          label: 'New phones',
          link: 'https://www.raylo.com/brands/new',
        },
        {
          label: 'Refurbished phones',
          link: 'https://www.raylo.com/brands/refurbished',
        },
        {
          label: 'About',
          link: 'https://www.raylo.com/post/iphone-upgrade-programme',
        },
      ],
    });
    setFooterListTwo({
      title: 'Customer Service',
      links: [
        {
          label: 'Help',
          link: 'https://help.raylo.com/',
        },
        {
          label: 'Contact Us',
          link: 'https://help.raylo.com/en/articles/3271547-contact-raylo',
        },
        {
          label: 'Careers',
          link: 'https://jobs.eu.lever.co/raylo',
        },
        {
          label: 'Guides',
          link: 'https://www.raylo.com/post',
        },
        {
          label: 'Legal',
          link: 'https://www.raylo.com/legal',
        },
        {
          label: 'Students',
          link: 'https://raylo.connect.studentbeans.com/uk',
        },
      ],
    });
    setFooterSocialMediaLinks([
      {
        icon: 'Facebook',
        link: 'https://www.facebook.com/joinraylo/',
      },
      {
        icon: 'Instagram',
        link: 'https://www.instagram.com/joinraylo/',
      },
      {
        icon: 'Twitter',
        link: 'https://twitter.com/raylo',
      },
    ]);
    setAboutMenuLinks([
      {
        displayName: 'about us',
        link: 'https://www.raylo.com/about',
      },
      {
        displayName: 'blog',
        link: 'https://www.raylo.com/blog',
      },
      {
        displayName: 'careers',
        link: 'https://www.raylo.com/careers',
      },
      {
        displayName: 'contact us',
        link: 'https://help.raylo.com/en/articles/3271547-contact-raylo',
      },
      {
        displayName: 'help',
        link: 'https://help.raylo.com/',
      },
      {
        displayName: 'sustainability',
        link: 'https://www.raylo.com/sustainability',
      },
    ]);
    setPdpValidationLogos([
      {
        title: 'This is money',
        url: 'https://assets-global.website-files.com/5ca6f1703977261264f0212e/60f44cc3d5febb68a0efeacb_tim.png',
      },
      {
        title: 'The Guardian',
        url: 'https://assets-global.website-files.com/5ca6f1703977261264f0212e/5dd3f4659cc04166e66064f9_460x215-TheGuardian.png',
      },
      {
        title: 'Tech Crunch',
        url: 'https://assets-global.website-files.com/5ca6f1703977261264f0212e/60edec633d5b2041bb70dc6b_techcrunch-logo-p-500.png',
      },
    ]);
    setCertifiedInfoItems([
      {
        linkHeader: 'Raylo lifetime warranty',
        cardIcon: 'CertifiedWarranty',
        cardTitle: 'Lifetime warranty',
        cardCopy: (
          <Copy color={palette.charcoal[400]} fontSize={16} lineHeight={24}>
            Every Raylo phone comes with a lifetime warranty whilst you’re on a Raylo subscription.
            If your device fails (unless it is damaged by you, another person or by accident) to
            meet our standards during that period, we’ll arrange for the device to be repaired or
            replaced at no additional cost. More information on warranty and how to make a claim can
            be found{' '}
            <TextLink href="https://help.raylo.com/en/articles/4094747-raylo-s-lifetime-warranty">
              here
            </TextLink>
          </Copy>
        ),
      },
      {
        linkHeader: 'Raylo 70-point check',
        cardIcon: 'CertifiedPhoneShield',
        cardTitle: 'Raylo 70-point check',
        cardCopy: (
          <>
            <Copy color={palette.charcoal[400]} fontSize={16} lineHeight={24}>
              Our refurbished devices have been used and spent a little time in people&apos;s bags
              and pockets and so might have some light scratches.
            </Copy>
            <Spacer height={12} />
            <Copy color={palette.charcoal[400]} fontSize={16} lineHeight={24}>
              All refurbished devices come with a screen protector and case and are Raylo Certified
              having passed through our comprehensive 70 point check, so it will look and feel
              great.
            </Copy>
            <Spacer height={12} />
            <Copy color={palette.charcoal[400]} fontSize={16} lineHeight={24}>
              And of course if you are not 100% happy, you have 14 days to return it to us and
              cancel for free.
            </Copy>
          </>
        ),
      },
      {
        linkHeader: 'Brand new accessories',
        cardIcon: 'CertifiedPhoneShield',
        cardTitle: 'Brand new accessories',
        cardCopy: (
          <>
            <Container>
              <Copy color={palette.charcoal[400]} fontSize={16} lineHeight={24}>
                All of our refurbished phones include:
              </Copy>
              <Spacer height={12} />
              <IconWithText icon="PhoneCharger" fontSize={16} fontColor={palette.charcoal[400]}>
                Brand new charger cable
              </IconWithText>
              <IconWithText
                icon="PhoneDoubleCamera"
                fontSize={16}
                fontColor={palette.charcoal[400]}
              >
                Eco-friendly case
              </IconWithText>
              <IconWithText icon="ScreenProtector" fontSize={16} fontColor={palette.charcoal[400]}>
                Screen protector
              </IconWithText>
            </Container>
          </>
        ),
      },
    ]);
    setBenefitsListItems([
      {
        icon: 'CustomerSupport36px',
        title: 'Wow Service',
        copy: 'Our UK based team works super hard. Check our reviews.',
      },
      {
        icon: 'PhoneDoubleCamera36px',
        title: 'Top notch accessories',
        copy: 'Free case with screen protection that you’ll actually want to use.',
      },
      {
        icon: 'EcologyGlobe36px',
        title: 'Greener too',
        copy: 'Our refurb and reuse approach means that all Raylo tech gets as many lives as possible.',
      },
      {
        icon: 'CertifiedPhoneShield36px',
        title: 'Peace of mind',
        copy: 'Great insurance cover from just £5.99/month. Underwritten by Collinson, made simple by Raylo.',
      },
    ]);
  }, [consumerIsBusiness]);

  useEffect(() => {
    if (isRayloPay) {
      setFooterListOne({
        title: 'Raylo Pay',
        links: [
          {
            label: 'Phones',
            link: '/phones',
          },
          ...(showOnlyPhones
            ? []
            : [
                {
                  label: 'Tablets',
                  link: '/tablets',
                },
                {
                  label: 'Laptops',
                  link: '/laptops',
                },
              ]),
          ...(showAboutUsLink
            ? [
                {
                  label: 'About Us',
                  link: '/about',
                },
                {
                  label: 'How It Works',
                  link: '/about/#how-it-works',
                },
              ]
            : []),
        ],
      });
      setFooterListTwo({
        title: 'Customer Service',
        links: [
          {
            label: 'Help',
            link: 'https://help.raylo.com/',
          },
          {
            label: 'Contact Us',
            link: 'https://help.raylo.com/en/articles/3271547-contact-raylo',
          },
        ],
      });
      setTrustPilotFooterLogo(
        'https://emailsignature.trustpilot.com/signature/en-GB/3/5cdd6c38b4acc6000128faf9/stars.png',
      );
    }
  }, [isRayloPay, showAboutUsLink, showOnlyPhones]);

  const router = useRouter();

  useEffect(() => {
    if (isRayloPay && router?.asPath?.includes('about')) {
      setBenefitsListItems([
        {
          icon: 'CustomerSupport36px',
          title: 'Wow Service',
          copy: 'Our UK based team works super hard. Check our reviews.',
        },
        {
          icon: 'ItemWarranty',
          title: 'Lifetime warranty',
          copy: 'Every Raylo Pay device comes with a lifetime warranty whilst you’re on a Raylo Pay lease.',
        },
        {
          icon: 'ItemShipment',
          title: 'Free next day delivery',
          copy: 'Choose your device and it could be in your hand the very next day',
        },
        {
          icon: 'CertifiedPhoneShield36px',
          title: 'Peace of mind',
          copy: 'Great insurance cover from just £5.99/month. Underwritten by Collinson, made simple by Raylo Pay.',
        },
      ]);
    }
  }, [isRayloPay, router?.asPath]);

  return {
    listOne,
    listTwo,
    listThree,
    footerListOne,
    footerListTwo,
    footerSocialMediaLinks,
    aboutMenuLinks,
    pdpValidationLogos,
    certifiedInfoItems,
    benefitsListItems,
    trustPilotFooterLogo,
  };
};

const [ContentProvider, useContentContext] = constate(useContent);
export { ContentProvider, useContentContext };
