import { ConsumerTypesEnum, NavBarWrapper, type NavDataModel, AboutSubMenuLinksEnum } from 'uibook';
import { useState, useEffect, useMemo } from 'react';
import { Link } from '../../elements/Link/Link';
import { useRouter } from 'next/router';
import { consumerTypePathBase } from '@/types/consumerTypes';
import { prefixUrlPathByConsumerType } from '@/utils/urls.utils';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { useAppContext } from '@/hooks/useAppContext';
import { useAuthTokenContext } from '@/hooks/useAuthTokenContext';
import { userIsLoggedIn } from '@/utils/auth/userAuth';

const GlobalNavBar = ({ navData }: { navData: NavDataModel }) => {
  const { consumerType, consumerIsBusiness } = useConsumerTypeContext();
  const { customerIsInMobileApp, authToken } = useAuthTokenContext();
  const { isRayloPay } = useAppContext();
  /**
   * Reading the `hasLoggedInCustomer` value directly from the context can cause a hydration issue
   * between the server-generated markup and the client-generated markup. We can conditionally show
   * the `ExistingCustomerNavBar` and `AccountBanner` by using a local state to store the value of
   * `hasLoggedInCustomer` and updating it when the context value changes.
   */
  const [localHasLoggedInCustomer, setLocalHasLoggedInCustomer] = useState(false);
  const router = useRouter();

  const hasAuthenticatedUser = useMemo(
    () => customerIsInMobileApp || userIsLoggedIn(authToken),
    [authToken, customerIsInMobileApp],
  );

  useEffect(() => {
    setLocalHasLoggedInCustomer(hasAuthenticatedUser);
  }, [hasAuthenticatedUser]);

  /**
   * Don't render the NavBar if the customer is logged in, or if the user is on the recommendations
   * page (in reality, users should only see the recommendations page if they are logged in, but put
   * this extra check here for local dev purposes).
   */
  if (
    localHasLoggedInCustomer ||
    ['/recommendations', '/business/recommendations'].includes(router.asPath)
  ) {
    return null;
  }

  const toggleUrl = (() => {
    if (consumerIsBusiness) {
      return router.asPath.replace(
        consumerTypePathBase[ConsumerTypesEnum.BUSINESS],
        consumerTypePathBase[ConsumerTypesEnum.PERSONAL],
      );
    }

    return prefixUrlPathByConsumerType(
      ConsumerTypesEnum.BUSINESS,
      router.asPath as Parameters<typeof prefixUrlPathByConsumerType>[1],
    );
  })();

  const aboutSubMenuLinksOverrides = (() => {
    /**
     * If the app is running on a merchant store, override the "ABOUT_US" link to point to `/about`
     * instead of `raylo.com/about`.
     */
    return isRayloPay
      ? [
          {
            displayName: AboutSubMenuLinksEnum.ABOUT_US,
            link: '/about',
          },
        ]
      : undefined;
  })();

  return (
    <NavBarWrapper
      app="products"
      accountHomeUrl={process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}
      productsUrl={consumerTypePathBase[consumerType]}
      navData={navData}
      LinkComponent={Link}
      consumerType={consumerType}
      toggleUrl={toggleUrl}
      aboutSubMenuLinksOverrides={aboutSubMenuLinksOverrides}
    />
  );
};

export default GlobalNavBar;
